import React from "react";

import { useNavigate } from "react-router-dom";
import { ButtonEnum } from "../../beans";
import PaddingManager from "../../components/PaddingManager";
import { Wrap } from "./styles";
import Button from "../../components/Button";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";

const NotFound = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<div className="title">{t("notFound.title")}</div>
					<div className="heading-md">{t("notFound.heading")}</div>
					<div>
						<p className="title-lg">{t("notFound.description")}</p>
					</div>
					<div className="action">
						<Button
							style={ButtonEnum.primary}
							onClick={() => navigate(routes.home)}
						>
							{t("notFound.back")}
						</Button>
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default NotFound;
