import { AlertEnum, ButtonEnum } from "../../beans";
import React from "react";
import Icon from "../Icon";
import { close } from "../../constants/icons";
import { Wrap } from "./styles";
import { info, warning, check } from "../../constants/icons";
import Button from "../Button";

type Props = {
	type: AlertEnum;
	full?: boolean;
	children?: React.ReactNode;
};

const Alert = (props: Props) => {
	const { type, full, children } = props;
	const [showAlert, setShowAlert] = React.useState(true);

	const getIcon = () => {
		switch (type) {
			case AlertEnum.info:
				return info;
			case AlertEnum.error:
			case AlertEnum.warning:
				return warning;
			case AlertEnum.success:
				return check;
			default:
				return info; // Provide a default icon
		}
	};

	const closeAlert = () => {
		setShowAlert(false);
	};

	return (
		<Wrap className={type} $show={showAlert} $full={full}>
			<div className="alert-message">
				<Icon icon={getIcon()} />
				{children}
			</div>
			<Button style={ButtonEnum.icon} onClick={closeAlert}>
				<Icon icon={close} />
			</Button>
		</Wrap>
	);
};

export default Alert;
