import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import SubsectionTitle from "../../components/SubsectionTitle";
import PaddingManager from "../../components/PaddingManager";
import Header from "../../components/Header";
import { Wrap } from "./styles";
import { listOrderRequest } from "../../store/Order";
import { useTranslation } from "react-i18next";
import OrderList from "../../components/OrderList";

const Production = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	// HOOKS
	useEffect(() => {
		dispatch(listOrderRequest());
	}, [dispatch]);

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<SubsectionTitle title={t("production.title")}></SubsectionTitle>
					<OrderList />
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default Production;
