import { ArtworkDetail, ItemRender } from "../beans";

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function getCanvaFromView(
  canvas: ItemRender[],
  view?: string,
  isAvatar?: boolean,
) {
  switch (view) {
    case "front_center":
    case "front_left":
      return isAvatar
        ? canvas.find((canva) => canva.view === "avatar_front")
        : canvas.find((canva) => canva.view === "front");

    case "full_back":
      return isAvatar
        ? canvas.find((canva) => canva.view === "avatar_back")
        : canvas.find((canva) => canva.view === "back");
    default:
      return canvas.find((canva) => canva.view === "front");
  }
}

export function getPrintLocationFromArtworkDetail(
  artworkDetail: ArtworkDetail[] | undefined,
) {
  if (!artworkDetail) return null;
  return artworkDetail.map((artwork: ArtworkDetail) => artwork.location);
}
