import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
import { appearThumb } from "../../design-system/animations";

export const Wrap = styled.div`
  background-color: var(--gray20);
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  gap: 40px;
  height: 100%;
  .thumbnails {
    margin-top: 40px;
    box-sizing: border-box;
    width: 64px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      &.loaded {
        scale: 0;
        animation: ${appearThumb} 0.3s linear forwards;

        &:nth-child(1) {
          animation: ${appearThumb} 0.1s linear 0.1s forwards;
        }
        &:nth-child(2) {
          animation: ${appearThumb} 0.1s linear 0.2s forwards;
        }
        &:nth-child(3) {
          animation: ${appearThumb} 0.1s linear 0.3s forwards;
        }
        &:nth-child(4) {
          animation: ${appearThumb} 0.1s linear 0.4s forwards;
        }
      }
    }
  }
  .product-preview {
    position: relative;
    margin: auto;
    margin-top: 0;
    .product-image {
      object-fit: contain;
      box-sizing: border-box;
      max-width: 100%;
      max-height: calc(100vh - 52px);
    }
    .artwork-area {
      position: absolute;
      z-index: 2;

      svg {
        position: absolute;
      }

      &.front_center {
        width: 28%;
        height: 9.3%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.item_014,
        &.item_015 {
          top: 33%;
        }

        &.item_016 {
          top: 31.5%;
        }

        img {
          position: absolute;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .artwork-preview {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 55px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    gap: 0;
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .thumbnails {
      margin: auto;
    }
    .product-preview {
      .product-image {
        max-width: 100%;
        max-height: 40vh;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .thumbnails {
      margin: auto;
      flex-direction: row;
      width: 100%;
      max-width: 100%;
      height: 64px;
      position: absolute;
      bottom: 0px;
      justify-content: space-around;
      align-items: center;
      left: 0;
      z-index: 9;
      background: var(--gray20);
      padding: 8px 0;
    }
  }
`;
