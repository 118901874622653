import styled from "styled-components";

export const Wrap = styled.div<{ $locked: boolean }>`
  cursor: ${(props) => (props.$locked ? "default" : "pointer")};

  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .design-name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: white;
    gap: 8px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    border-bottom: ${(props) =>
      props.$locked ? "none" : " 1px dotted var(--gray30)"};
    color: var(--gray130);
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      border-bottom: ${(props) =>
        props.$locked ? "none" : " 1px dotted var(--gray30)"};
    }
  }
  .edit-block {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;

    form {
      width: 100%;
      input {
        width: 100%;
        padding: 10px;
      }
    }
  }
`;
