import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
import { appearFromBottom } from "../../design-system/animations";
export const Wrap = styled.div`
  z-index: 9;
  position: fixed;
  bottom: 20px;
  background: var(--white);
  padding: 16px;
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  animation: ${appearFromBottom} 0.3s ease-in-out;

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 100%;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    .count {
      border-bottom: 1px solid var(--purple100);
      width: 100%;
      text-align: center;
      padding: 16px;
      padding-top: 0;
    }
    .vertical {
      display: none;
    }
  }
`;
