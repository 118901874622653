import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div<{
  $selected: boolean;
  $minimal: boolean;
  $locked: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  .action-left {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 9;
    input {
      accent-color: var(--purple100);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &.locked {
    .action-left {
      cursor: pointer;
      background-color: var(--gray90);
      border-radius: 4px;
      padding: 4px;
      display: flex;
      path {
        fill: var(--white);
      }
      .locked-button {
        display: flex;
      }
      .locked-label {
        display: flex;
        color: white;
        font-size: 12px;
        align-items: center;
        padding-left: 4px;
      }
    }
  }
  .dot-menu {
    position: absolute;
    top: -8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 9;
  }

  .actions {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 8px;
    gap: 16px;
    bottom: 0px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    button {
      padding: 4px;
      font-size: 12px;
    }
  }

  .design-preview-card {
    position: relative;
    border: ${(props) =>
      props.$selected ? "1px solid var(--purple100)" : "0"};
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 8px;
    .artwork {
      position: relative;
    }

    &.multiple {
      .artwork {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    &:hover {
      .actions {
        opacity: 1;
        bottom: 30px;
      }
      &.multiple {
        .artwork:nth-of-type(1) {
          display: none;
        }
        .artwork:nth-of-type(2) {
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .actions {
      opacity: 1;
      bottom: 30px;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .actions {
      button {
        font-size: 12px;
      }
    }
  }
`;
