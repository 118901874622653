import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
	width: 100%;
	position: relative;
	right: 0;
	height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&#cart {
		margin-right: 8px;
	}
	.item-action {
		display: flex;
		width: max-content;
	}
	.items {
		display: none;
		flex-direction: column;
		align-items: end;
		background: var(--white);
		position: absolute;
		right: 0;
		top: 50px;
		padding: 8px;
		border-top: 1px solid var(--purple30);
		border-radius: 0 0 4px 4px;
		box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
		flex-direction: column;
		align-items: start;
		justify-content: center;
		min-width: 200px;
		max-width: 300px;
		width: max-content;
		&:before {
			content: "";
			display: inline-block;
			width: 5px;
			height: 5px;
			position: absolute;
			border-width: 0 3px 3px 0;
			border: solid var(--purple30);
			background: var(--purple30);
			transform: rotate(-135deg);
			top: -3px;
			right: 4px;
			padding: 3px;
			z-index: -1;
		}
		.title-sm {
			padding: 8px 0;
			border-bottom: 1px solid var(--gray30);
			width: 100%;
		}
	}
	&.open {
		.items {
			display: flex;
		}
	}
	&#hamburger {
		.items {
			left: 0;
		}
	}
	@media only screen and (max-width: ${breakpoints.desktop}px) {
		&#user,
		&#lang {
			display: none;
		}
	}

	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.items {
			&:before {
				display: none;
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		&#hamburger {
			.items {
				width: 100%;
				transform: none;
				max-width: 100%;
				min-width: 100vw;
				left: -16px;
				padding-left: 16px;
				transform: none;
				.action-item {
					.menu {
						padding-left: 0;
					}
				}
			}
		}

		.items {
			left: 50%;
			transform: translateX(-50%);
		}
	}
`;
