import React from "react";
import { MouseEventHandler } from "react";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { bigStars } from "../../constants/icons";
import { useTranslation } from "react-i18next";

import { ButtonEnum } from "../../beans";

import AirTableFormButton from "../AirTableFormButton";

type Props = {
	size?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
};
const RequestProductCard = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Wrap onClick={props.onClick} key={"custom"} className={props.size}>
			<div className="request-box">
				<Icon icon={bigStars} />
				<div
					className="description body-sm"
					dangerouslySetInnerHTML={{ __html: t("requestProduct.description") }}
				/>
				<AirTableFormButton
					url="https://airtable.com/embed/app62wHra2YvuQZR7/pagoOar1xroPYi9C7/form"
					style={ButtonEnum.primary}
					label={t("airtable.product")}
				/>
			</div>
		</Wrap>
	);
};

export default RequestProductCard;
