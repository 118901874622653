import { useEffect } from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocation } from "react-router";

declare global {
	interface Window {
		hsConversationsSettings: {
			loadImmediately: boolean;
		};
	}
}

const Hubspot = () => {
	const { setPathPageView, setIdentity } = useTrackingCode();
	const user = useSelector((state: RootState) => state.user.data);
	const location = useLocation();

	useEffect(() => {
		window.HubSpotConversations?.on("widgetClosed", () => {
			window.HubSpotConversations?.widget?.remove();
		});
	}, [window.HubSpotConversations]);

	useEffect(() => {
		const oldScript = document.getElementById("hs-script-loader");
		if (oldScript) {
			document.head.removeChild(oldScript);
		}
		const script = document.createElement("script");
		script.src = `//js-eu1.hs-scripts.com/26490882.js?v=${new Date().getTime()}`;
		script.id = "hs-script-loader";
		script.async = true;
		script.defer = true;

		window.hsConversationsSettings = {
			loadImmediately: false,
		};

		document.head.appendChild(script);
		setPathPageView(document.location.pathname);
		user && setIdentity(user.email);

		script.addEventListener("load", () => {
			window?.HubSpotCallsToActions?.refresh();
		});
	}, [location, setIdentity, setPathPageView, user]);

	return null;
};
export default Hubspot;
