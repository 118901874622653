import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { setCSFRToken } from "../../api/csfr";
import { loginRequest } from "../Login";
import { authUrl } from "../../constants/endpoints";
import { getAuthHeader } from "../../api/auth";
import { User } from "../../beans";
import { showErrorModal } from "../UI";
import { getErrors } from "../../utils/errors";

export interface RegistrationState {
	data: User | null;
	error: null | string | { serverError: string[] };
	loading: boolean;
	loaded: boolean;
}

const initialState: RegistrationState = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

// DEFINE SAGAS
function* registrationSaga(
	action: PayloadAction<{
		email: string;
		password: string;
	}>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${authUrl}/registration/`, {
			method: "POST",
			body: JSON.stringify(action.payload),
			headers: headers,
			credentials: "include",
		});

		const data = yield response.json();
		if (response.ok) {
			yield put(registrationSuccess(data));
			setCSFRToken();

			const loginData = {
				email: action.payload.email,
				password: action.payload.password,
			};
			yield put(loginRequest(loginData));
		} else {
			yield put(registrationFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		console.error("ERROR REGISTRATION", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(registrationFailure({ serverError: [errorMessage] }));
		yield put(showErrorModal(errorMessage));
	}
}

export const registrationSlice = createSlice({
	name: "registration",
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		registrationRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		registrationSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		registrationFailure: (
			state,
			action: PayloadAction<{ serverError: string[] }>
		) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
	},
});

// TODO define actions better in bindActionCreators.tsx
export const { registrationRequest, registrationSuccess, registrationFailure } =
	registrationSlice.actions;

export default registrationSlice.reducer;

export function* sagas() {
	yield takeEvery(registrationRequest.type, registrationSaga);
}
