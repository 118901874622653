import { sagas as ArtworkDetailSaga } from "./ArtworkDetail";
import { sagas as BillingSaga } from "./Billing";

import { sagas as DesignSaga } from "./Design";
import { sagas as FabricSaga } from "./Fabric";
import { sagas as LogingSaga } from "./Login";
import { sagas as OrderSaga } from "./Order";
import { sagas as PasswordSaga } from "./Password";
import { sagas as PrintLocationSaga } from "./PrintLocation";

import { sagas as ProductionSaga } from "./Production";
import { sagas as ProductionRangeSaga } from "./ProductionRange";
import { sagas as RegistrationSaga } from "./Registration";
import { sagas as ShippingSaga } from "./Shipping";
import { sagas as UserDesignSaga } from "./UserDesign";
import { sagas as UserSaga } from "./User";
import { sagas as UISaga } from "./UI";

const sagas = [
  // NOTE: needs to be one line per store
  ArtworkDetailSaga,
  BillingSaga,
  DesignSaga,
  FabricSaga,
  LogingSaga,
  OrderSaga,
  PasswordSaga,
  PrintLocationSaga,
  ProductionSaga,
  ProductionRangeSaga,
  RegistrationSaga,
  ShippingSaga,
  UISaga,
  UserSaga,
  UserDesignSaga,
];

export default sagas;
