import React from "react";
import { Wrap } from "./styles";
import { RootState, AppDispatch } from "../../store";
import { useSelector, useDispatch } from "react-redux";

import { useEffect } from "react";
import { ActionCardEnum, AlertEnum, ButtonEnum, PrintType } from "../../beans";

import { info as infoIcon } from "../../constants/icons";

import SubsectionTitle from "../SubsectionTitle";

import { getPrintTypeThunk, selectLabelPrintType } from "../../store/PrintType";
import FileDrop from "../FileDrop";
import ArtworkDetail from "../ArtworkDetail";

import { showInfoBox } from "../../store/UI";
import Button from "../Button";
import Icon from "../Icon";
import Alert from "../Alert";
import ActionCard from "../ActionCard";
import LoadingOptions from "../LoadingOptions";
import { deleteUserDesignLabelThunk } from "../../store/UserDesign";
import { useTranslation } from "react-i18next";

const LabelOptions = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const artwork = userDesign?.label;
	const labelDetail = useSelector(
		(state: RootState) => state.userDesign.data?.label
	);
	const printTypes = useSelector((state: RootState) => state.printType.label);
	const selectedPrintType = useSelector(
		(state: RootState) => state.printType.selectedLabel
	);
	useEffect(() => {
		dispatch(getPrintTypeThunk());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handlePrintType = (printType: PrintType) => {
		dispatch(selectLabelPrintType(printType));
		if (printType.id === "0")
			dispatch(
				deleteUserDesignLabelThunk({
					designId: userDesign?.id,
					labelId: artwork?.id,
				})
			);
	};

	const getCurrentArtwork = () => {
		return labelDetail;
	};

	const renderPrintTypeAlert = () => {
		return (
			<Alert type={AlertEnum.warning}>
				<div>{t("labelOptions.labelAlert")}</div>
			</Alert>
		);
	};
	return (
		<Wrap>
			{renderPrintTypeAlert()}

			{printTypes.length > 0 ? (
				<div className="print-options">
					<SubsectionTitle title={t("labelOptions.label")} />
					<div className="print-placement-options">
						{printTypes.map((printType: PrintType, k) => {
							return (
								<ActionCard
									info
									code={printType.type}
									onClick={() => handlePrintType(printType)}
									key={`print_type_${k}`}
									type={ActionCardEnum.simple}
									title={t(printType.label)}
									label={t(printType.description)}
									selected={selectedPrintType?.id === printType.id}
								/>
							);
						})}
					</div>
				</div>
			) : (
				<LoadingOptions />
			)}
			{selectedPrintType?.id !== "0" && (
				<SubsectionTitle title={t("labelOptions.artwork")}>
					<Button
						style={ButtonEnum.icon}
						onClick={() =>
							dispatch(
								showInfoBox({
									open: true,
									title: t("labelOptions.artworkGuidelines"),
									key: "artwork_guidelines",
								})
							)
						}
					>
						<Icon info icon={infoIcon}></Icon>
					</Button>
				</SubsectionTitle>
			)}
			{!getCurrentArtwork() && selectedPrintType?.id !== "0" ? (
				<FileDrop label />
			) : (
				<div className="print-options">
					<ArtworkDetail label artwork={getCurrentArtwork()} />
				</div>
			)}
		</Wrap>
	);
};

export default LabelOptions;
