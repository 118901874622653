import React from "react";
import { ButtonEnum } from "../../beans";
import { globe } from "../../constants/icons";
import Button from "../Button";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { Wrap } from "./styles";
import DropDown from "../DropDown";

type Props = {
	menu?: boolean;
};
const LangSwitcher = (props: Props) => {
	const { t, i18n } = useTranslation();
	const { menu } = props;
	const changeLanguageHandler = (lang: string) => {
		i18n.changeLanguage(lang);
		location.reload();
	};

	return (
		<Wrap>
			{!menu ? (
				<div>
					<DropDown
						id="lang"
						item={
							<Button style={ButtonEnum.icon}>
								<Icon icon={globe} />{" "}
								<span className="lang">{i18n.resolvedLanguage}</span>
							</Button>
						}
					>
						<div className="title-sm">{t("lang.language")}</div>
						<Button
							style={ButtonEnum.menu}
							onClick={() => changeLanguageHandler("en")}
						>
							English
						</Button>
						<Button
							style={ButtonEnum.menu}
							onClick={() => changeLanguageHandler("es")}
						>
							Español
						</Button>
					</DropDown>
				</div>
			) : (
				<Button
					id="lang-hamburger"
					style={ButtonEnum.menu}
					onClick={() =>
						i18n.resolvedLanguage === "es"
							? changeLanguageHandler("en")
							: changeLanguageHandler("es")
					}
				>
					{t("lang.switch")}
				</Button>
			)}
		</Wrap>
	);
};

export default LangSwitcher;
