export const getErrors = (
  errors: Record<string, string | string[]>,
): string => {
  let errorMessage = "";
  const appendErrorMessage = (error: string) => {
    errorMessage += `${error} <br/> `;
  };

  for (const [key] of Object.entries(errors)) {
    if (errors[key] instanceof Array) {
      errors[key]?.map(appendErrorMessage);
    } else {
      errorMessage = errors[key];
    }
  }
  return errorMessage;
};
