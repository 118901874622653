import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{
	$open?: boolean;
	$error?: boolean;
	$minimal?: boolean;
}>`
	position: absolute;
	z-index: 999999999;
	top: 0;
	left: 0;
	visibility: ${(props) => (props.$open ? "show" : "hidden")};
	opacity: ${(props) => (props.$open ? "1" : "0")};
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	transition: all 0.3s ease-in-out;
	.modal-container {
		position: absolute;
		z-index: 999;
		transform: translateX(0);
		width: var(--sidebarWidth);
		background-color: var(--white);
		transition: all 0.3s ease-in-out;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.modal-content {
			padding: ${(props) => (props.$minimal ? "0px" : "24px 40px")};
		}
		.modal-header {
			padding: 0 40px;
			padding: ${(props) => (props.$minimal ? "24px" : "24 40px")};
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-items: center;
			height: var(--headerHeight);
			border-bottom: ${(props) =>
				props.$minimal ? "none" : `1px solid var(--purple30)`};
			.title {
				color: var(--red110);
				color: ${(props) =>
					props.$error ? "var(--red110);" : "var(--gray130);"};
				display: flex;
				align-items: center;
				gap: 16px;
				svg {
					path {
						fill: var(--red110);
					}
				}
			}
		}
		footer {
			display: flex;
			gap: 16px;
			justify-content: flex-end;
			margin-top: ${(props) => (props.$minimal ? "none" : `24px`)};
		}
		.title-sm {
			color: var(--gray130);
		}
	}

	@media only screen and (max-width: ${breakpoints.mobile}px) {
		height: 100%;
		.modal-container {
			top: 50%;
			width: calc(100% - 24px);
			transform: translate(-50%, -50%);
			max-height: calc(93%);
			overflow: scroll;
			height: auto;
			.modal-header {
				padding: 16px 20px;
			}
			.modal-content {
				padding: ${(props) => (props.$minimal ? "0px" : "16px 20px")};
			}
		}
	}
`;
