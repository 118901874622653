import { ButtonEnum, UserDesign } from "../../beans";
import React, { useState } from "react";
import { Wrap } from "./styles";
import Button from "../Button";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { patchUserDesignRequest } from "../../store/UserDesign";
import { useTranslation } from "react-i18next";
import { pencil } from "../../constants/icons";
import Icon from "../Icon";

type Props = {
  design: UserDesign;
};

const EditDesignName = (props: Props) => {
  const { design } = props;
  const [showEditName, setShowEditName] = useState(false);
  const [name, setName] = useState(design?.name);
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const editProductName = () => {
    dispatch(
      patchUserDesignRequest({
        designId: design?.id?.toString() || "",
        payload: { name: name },
      }),
    );
    setShowEditName(false);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setName(e.target.value);
  };

  return (
    <Wrap $locked={design.locked}>
      {!showEditName ? (
        <div
          className="design-name title-sm"
          onClick={() => !design.locked && setShowEditName(true)}
        >
          <div className="name">{name}</div>
          <div className="design-name-edit">
            {!design.locked && (
              <Button
                style={ButtonEnum.link}
                onClick={() => setShowEditName(true)}
              >
                <Icon icon={pencil} />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="edit-block">
          <form onSubmit={editProductName}>
            <div className="input-row">
              <input
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={handleChangeName}
                placeholder={design.name}
              />
            </div>
          </form>
          <Button
            style={ButtonEnum.primary}
            className="small"
            onClick={() => editProductName()}
          >
            {t("button.save")}
          </Button>
          <Button
            className="small"
            style={ButtonEnum.secondary}
            onClick={() => setShowEditName(false)}
          >
            {t("button.cancel")}
          </Button>
        </div>
      )}
    </Wrap>
  );
};

export default EditDesignName;
