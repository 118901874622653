import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
	.production-designs-container {
		display: flex;
		gap: 16px;
		.production-designs-content {
			display: flex;
			gap: 16px;
			.print-preview {
				width: 120px;
			}
		}
		.production-designs-actions {
			text-align: left;
			.title {
				color: var(--gray130);
			}
			.actions {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}

		.sizes-container {
			margin-top: 16px;
			display: flex;
			gap: 16px;
			align-items: center;
			text-align: center;

			.size-row {
				color: var(--gray130);
				display: flex;
				flex-direction: column;
				gap: 4px;
				.size {
					color: var(--gray90);
				}
				.total {
					color: var(--gray130);
					font-family: "Helvetica-Bold";
					text-align: left;
				}
			}
		}
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		.production-designs-container {
			flex-direction: column;
			.production-designs-content {
			}
		}
		.production-designs-actions {
			gap: 8px;
			display: flex;
			flex-direction: column;
			.sizes-container {
				text-align: left;
				display: flex;
				gap: 8px;
				flex-wrap: wrap;
				margin-top: 0;
			}
		}
	}

	@media only screen and (max-width: ${breakpoints.mobile}px) {
		.production-designs-container {
			.production-designs-content {
				display: flex;
				gap: 16px;
				padding: 20px;
				justify-content: space-evenly;
				background: var(--gray20);
				.print-preview {
					/* width: 50%; */
				}
			}
		}
	}
`;
