import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getDesignRequest } from "../../store/Design";
import { ButtonEnum } from "../../beans";
import { routes } from "../../constants/routes";

import SubsectionTitle from "../../components/SubsectionTitle";
import PaddingManager from "../../components/PaddingManager";

import Header from "../../components/Header";
import Button from "../../components/Button";

import { Wrap } from "./styles";
import { resetOrders } from "../../store/Order";
import { resetProduction } from "../../store/Production";
import { resetUserDesign } from "../../store/UserDesign";
import { resetFabric } from "../../store/Fabric";
import { resetTab } from "../../store/UI";
import { resetShipping } from "../../store/Shipping";
import { resetBilling } from "../../store/Billing";

import { useTranslation } from "react-i18next";
import BottomBar from "../../components/BottomBar";
import DesignList from "../../components/DesignList";

const Design = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	// LOCAL STATE

	const selectedDesigns = useSelector(
		(state: RootState) => state.design.selected
	);

	const isLogged = useSelector((state: RootState) => state.login.logged);

	// HOOKS
	useEffect(() => {
		dispatch(resetProduction());
		dispatch(resetOrders());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetShipping());
		dispatch(resetBilling());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getDesignRequest(0));
	}, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<SubsectionTitle title={t("design.title")}>
						<Button
							style={ButtonEnum.primary}
							onClick={() => navigate(routes.catalog)}
							disabled={selectedDesigns.length > 0}
						>
							{t("design.createNewDesign")}
						</Button>
					</SubsectionTitle>
					<DesignList />
				</PaddingManager>
				{selectedDesigns.length > 0 && (
					<BottomBar selectedDesigns={selectedDesigns} />
				)}
			</Wrap>
		</>
	);
};

export default Design;
