import React from "react";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
	hideActionModal,
	listInfoBoxContentsRequest,
	resetActionModal,
	resetErrorModal,
	resetModal,
	resetTab,
	showActionModal,
} from "../../store/UI";
import { userRequest } from "../../store/User";
import { logoutRequest } from "../../store/Login";
import { ButtonEnum, ModalActionButton, ModalEnum } from "../../beans";
import {
	goToOrderProduction,
	isDesignRoute,
	routes,
} from "../../constants/routes";
import {
	close,
	account,
	signout,
	hamburger,
	cart,
} from "../../constants/icons";
import PaddingManager from "../PaddingManager";
import Logo from "../Logo";
import Modal from "../Modal";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import InfoBox from "../InfoBox";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";
import { getPrintLocationThunk } from "../../store/PrintLocation";
import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import { resetUserDesign } from "../../store/UserDesign";
import { FullStory } from "@fullstory/browser";
import { getCookie, setCookie } from "../../utils/cookies";
import { useTranslation } from "react-i18next";
import LangSwitcher from "../LangSwitcher";

import { getCurrentCartRequest } from "../../store/Order";
import DropDown from "../DropDown";
import Divider from "../Divider";

type Props = {
	product?: boolean;
	hidden?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
};

declare global {
	interface Window {
		HubSpotConversations: any;
	}
}

const Header = (props: Props) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { product } = props;
	const ui = useSelector((state: RootState) => state.ui);
	const user = useSelector((state: RootState) => state.user.data);
	const order = useSelector((state: RootState) => state.order.data);
	const isLogged = useSelector((state: RootState) => state.login.logged);
	const [hamburgerOpen, setHamburgerOpen] = useState(false);
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		dispatch(getPrintLocationThunk());
	}, [dispatch]);

	useEffect(() => {
		if (isLogged && !order?.id) {
			dispatch(getCurrentCartRequest());
		}

		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [order?.id]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (width <= 768) {
			if (!getCookie("mobile-popup") && isDesignRoute(window.location.pathname))
				dispatch(
					showActionModal({
						title: t("header.mobilePopup.title"),
						error: `<div class="label-lg"><div><img src="https://fabbric-public-images.ams3.digitaloceanspaces.com/UI/platform-desktop.png" style="max-width:100%;" /></div><p>${t("header.mobilePopup.description")}</p></div>`,
						actions: [
							{
								style: ButtonEnum.primary,
								onClick: () => {
									setCookie("mobile-popup", "true");
									dispatch(hideActionModal());
								},

								label: t("header.mobilePopup.button"),
							},
						],
						onClose: () => {
							setCookie("mobile-popup", "true");
							dispatch(resetActionModal());
							dispatch(hideActionModal());
						},
					})
				);
		}
		if (isLogged && !user) {
			dispatch(userRequest());
		}
	}, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(listInfoBoxContentsRequest());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// TODO wait for user id from Colin
	useEffect(() => {
		const fs = getCookie("fs_init");
		if (user && !fs) {
			setCookie("fs_init", "true");
			FullStory("setIdentity", {
				uid: user.id.toString(),
				properties: {
					displayName: `${user.first_name} ${user.last_name}`,
					email: user.email,
				},
			});
		}
	}, [user]);

	const goToHome = () => {
		dispatch(resetProduction());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetShipping());
		dispatch(resetBilling());
		dispatch(resetUserDesign());
		navigate(routes.home);
	};

	const closeHeader = () => {
		goToHome();
	};

	const renderInfoBox = () => {
		return (
			<InfoBox
				open={ui.infoBox.open}
				title={ui.infoBox.active.title}
				content={ui.infoBox.active.content}
				image={ui.infoBox.active.image}
			/>
		);
	};

	const renderModalError = () => {
		return (
			<Modal
				type={ModalEnum.error}
				key="errorModal"
				onClose={() => dispatch(resetErrorModal())}
				open={ui.errorModal.open}
				title={t("header.modal.errorTitle")}
				error
				content={
					<p dangerouslySetInnerHTML={{ __html: ui.errorModal.error }}></p>
				}
			/>
		);
	};

	const renderModal = () => {
		return (
			<Modal
				type={ModalEnum.error}
				key="modal"
				onClose={() => dispatch(resetModal())}
				open={ui.modal.open}
				title={ui.modal.title}
				content={ui.modal.content ?? <></>}
			/>
		);
	};

	const renderActionModal = () => {
		return (
			<Modal
				type={ui.actionModal.type}
				key="actionModal"
				open={ui.actionModal.open}
				minimal={ui.actionModal.minimal}
				title={ui.actionModal.title || t("header.modal.attentionTitle")}
				content={
					<>
						<div
							dangerouslySetInnerHTML={{ __html: ui.actionModal.error }}
						></div>
						<footer>
							{ui.actionModal.actions.map((action: ModalActionButton) => (
								<Button
									style={action.style}
									onClick={action.onClick}
									key={action.label}
								>
									{action.label}
								</Button>
							))}
						</footer>
					</>
				}
				onClose={
					ui.actionModal.onClose
						? ui.actionModal.onClose
						: () => dispatch(resetActionModal())
				}
			/>
		);
	};

	const renderProductMenu = () => {
		return (
			<>
				{product ? (
					<div className="product-details">
						<div className="logo">
							<Button
								className="logo-button"
								style={ButtonEnum.link}
								onClick={() => goToHome()}
							>
								<Logo />
							</Button>
						</div>
					</div>
				) : null}
			</>
		);
	};

	const renderNavigationMenu = () => {
		return (
			<ul className="navigationMenu" role="navigation">
				<li>
					<a
						onClick={() => navigate(routes.home)}
						className={window.location.pathname === routes.home ? "active" : ""}
					>
						{t("header.menu.home")}
					</a>
				</li>
				<li>
					<a
						onClick={() => navigate(routes.catalog)}
						className={
							window.location.pathname === routes.catalog ? "active" : ""
						}
					>
						{t("header.menu.catalog")}
					</a>
				</li>
				<li>
					<a
						onClick={() => navigate(routes.design)}
						className={
							window.location.pathname === routes.design ? "active" : ""
						}
					>
						{t("header.menu.design")}
					</a>
				</li>
				<li>
					<a
						onClick={() => navigate(routes.production)}
						className={
							window.location.pathname === routes.production ? "active" : ""
						}
					>
						{t("header.menu.production")}
					</a>
				</li>
			</ul>
		);
	};

	const hasItemInCart = () => {
		if (!order) return false;
		return order.orderitem_set?.length > 0;
	};

	return (
		<>
			<Wrap
				className={product ? "product" : ""}
				role="header"
				$hidden={props.hidden}
			>
				<PaddingManager>
					<div className="header-container">
						{/* HAMBURGER */}
						{!product && (
							<div className="mobile-menu">
								<DropDown
									id="hamburger"
									item={
										<Button
											style={ButtonEnum.icon}
											onClick={() => {
												setHamburgerOpen(!hamburgerOpen);
											}}
										>
											<Icon icon={close} />
											<Icon icon={hamburger} />
										</Button>
									}
								>
									<div
										className={`logged-actions ${hamburgerOpen ? "open" : "closed"}`}
									>
										{/* USER */}
										<div className="action-item-text body-lg">
											{t("header.hi")}, {user?.first_name}
										</div>
										{/* MENU */}
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.home)}
												className={
													window.location.pathname === routes.home
														? "active"
														: ""
												}
											>
												{t("header.menu.home")}
											</Button>
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.catalog)}
												className={
													window.location.pathname === routes.catalog
														? "active"
														: ""
												}
											>
												{t("header.menu.catalog")}
											</Button>
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.design)}
												className={
													window.location.pathname === routes.design
														? "active"
														: ""
												}
											>
												{t("header.menu.design")}
											</Button>
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.production)}
												className={
													window.location.pathname === routes.production
														? "active"
														: ""
												}
											>
												{t("header.menu.production")}
											</Button>
										</div>
										<div className="action-item">
											<Divider />
										</div>
										{/* LANG  */}
										<div className="action-item">
											<LangSwitcher menu />
										</div>
										{/* LOGOUT */}
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => {
													dispatch(logoutRequest());
												}}
											>
												<Icon icon={signout} />
												{t("header.signout")}
											</Button>
										</div>
									</div>
								</DropDown>
							</div>
						)}
						{!product ? (
							<div className="logo">
								<Button
									className="logo-button"
									style={ButtonEnum.link}
									onClick={() => goToHome()}
								>
									<Logo />
								</Button>
							</div>
						) : (
							<Button style={ButtonEnum.icon} onClick={() => closeHeader()}>
								<Icon icon={close} />
							</Button>
						)}
						<div className="content">
							{/* PRODUCT BAR */}
							{product && renderProductMenu()}
							{/* NAVIGATION MENU */}
							{!product && isLogged && renderNavigationMenu()}
						</div>
						<div className="header-action-icons">
							{!product && (
								<>
									<DropDown
										id="user"
										item={
											<Button style={ButtonEnum.icon}>
												<Icon icon={account} />
												{/* {t("header.account")} */}
											</Button>
										}
									>
										<div className="title-sm">{`${t("header.hi")}, ${user?.first_name}`}</div>
										<Button
											style={ButtonEnum.menu}
											onClick={() => {
												dispatch(logoutRequest());
											}}
										>
											<Icon icon={signout} />
											{t("header.signout")}
										</Button>
									</DropDown>
									<LangSwitcher />
									{/* HELP */}
									<Button
										style={ButtonEnum.primary}
										className="small"
										onClick={() => {
											window.HubSpotConversations.widget.load({
												widgetOpen: true,
											});
											setTimeout(() => {
												window.HubSpotConversations.widget.open();
											}, 300);
										}}
									>
										{t("header.help.needHelp")}
									</Button>
									{/* CART */}
									<DropDown
										id="cart"
										item={
											<Button
												style={ButtonEnum.icon}
												onClick={() =>
													hasItemInCart() && order?.id
														? navigate(goToOrderProduction(order.id))
														: null
												}
											>
												<Icon icon={cart} />
												{hasItemInCart() && order?.id && (
													<span className="cart-items">
														{order.orderitem_set.length}
													</span>
												)}
											</Button>
										}
									></DropDown>
								</>
							)}

							{/* CLOSE PRODUCT MENU */}
							{product && (
								<Button
									style={ButtonEnum.primary}
									className="small"
									onClick={() => {
										window.HubSpotConversations.widget.load({
											widgetOpen: true,
										});
										setTimeout(() => {
											window.HubSpotConversations.widget.open();
										}, 300);
									}}
								>
									{t("header.help.needHelp")}
								</Button>
							)}
						</div>
					</div>
				</PaddingManager>
			</Wrap>
			{/* MODALS  */}
			{ui.errorModal.open && renderModalError()}
			{ui.actionModal.open && renderActionModal()}
			{ui.modal.open && renderModal()}

			{renderInfoBox()}
		</>
	);
};

export default Header;
