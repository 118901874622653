import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PrintLocation } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getAuthHeader } from "../../api/auth";
import { StrictEffect, put, takeEvery, call } from "redux-saga/effects";

export interface PrintLocationState {
	data: PrintLocation[];
	availables: PrintLocation[];
	error: null | string;
	selected: PrintLocation | null;
	selectedLabel: PrintLocation | null;
	loading: boolean;
	loaded: boolean;
}

const initialState: PrintLocationState = {
	data: [],
	availables: [],
	error: null,
	selected: null,
	selectedLabel: null,
	loading: false,
	loaded: false,
};

// SAGAS
function* getAvailablePrintlocationsSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/designs/printlocation/available_on/${action.payload}/`,
			{
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(getAvailablePrintlocationsSuccess(data));
		} else {
			yield put(getAvailablePrintlocationsFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

export const getPrintLocationThunk = createAsyncThunk(
	"printlocation/request",
	async () => {
		const response = await fetch(`${apiUrl}/designs/printlocation/`, {
			headers: getAuthHeader(),
		});

		return await response.json();
	}
);

export const getLabelPrintLocationThunk = createAsyncThunk(
	"labelprintlocation/request",
	async () => {
		const response = await fetch(`${apiUrl}/designs/printlocation/label/`, {
			headers: getAuthHeader(),
		});

		return await response.json();
	}
);

export const printLocationSlice = createSlice({
	name: "printLocation",
	initialState,
	reducers: {
		selectPrintLocation: (
			state,
			action: PayloadAction<PrintLocation | null>
		) => {
			state.selected = action.payload;
		},
		selectLabelPrintLocation: (state, action: PayloadAction<PrintLocation>) => {
			if (action.payload) state.selectedLabel = action.payload;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getAvailablePrintlocationsRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		getAvailablePrintlocationsSuccess: (
			state,
			action: PayloadAction<PrintLocation[]>
		) => {
			state.availables = action.payload;
			state.loaded = true;
			state.loading = false;
			state.selected = action.payload[0];
		},
		getAvailablePrintlocationsFailure: (
			state,
			action: PayloadAction<string>
		) => {
			state.error = action.payload;

			state.loaded = true;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPrintLocationThunk.pending, (state) => {
			state.data = [];
		});
		builder.addCase(getPrintLocationThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.selected = action.payload[0];
		});
		builder.addCase(getPrintLocationThunk.rejected, (state, action) => {
			state.error = action.error?.message ?? "Unknown error";
		});

		builder.addCase(getLabelPrintLocationThunk.pending, (state) => {
			state.data = [];
		});
		builder.addCase(getLabelPrintLocationThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.selectedLabel = action.payload[0];
		});
		builder.addCase(getLabelPrintLocationThunk.rejected, (state, action) => {
			state.error = action.error?.message ?? "Unknown error";
		});
	},
});

export const {
	selectPrintLocation,
	selectLabelPrintLocation,
	getAvailablePrintlocationsRequest,
	getAvailablePrintlocationsSuccess,
	getAvailablePrintlocationsFailure,
} = printLocationSlice.actions;

export default printLocationSlice.reducer;
export function* sagas() {
	yield takeEvery(
		getAvailablePrintlocationsRequest,
		getAvailablePrintlocationsSaga
	);
}
