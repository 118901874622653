import React, { useState } from "react";
import {
	AlertEnum,
	ArtworkDetail as ArtworkDetailType,
	ButtonEnum,
	PrintTypeEnum,
} from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { deleteArtworkDetailRequest } from "../../store/ArtworkDetail";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserDesignLabelThunk } from "../../store/UserDesign";
import { useTranslation } from "react-i18next";

import LoadingItem from "../LoadingItem";
import Alert from "../Alert";

type Props = {
	label?: boolean;
	artwork?: ArtworkDetailType;
};
const ArtworkDetail = (props: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { artwork, label } = props;
	const [error, setError] = useState(false);
	const userDesignState = useSelector((state: RootState) => state.userDesign);
	const userDesign = userDesignState.data;

	useState(() => {
		artwork &&
			setError(
				(artwork.type === PrintTypeEnum.screen_print ||
					artwork.type === PrintTypeEnum.embroidery) &&
					artwork?.colors?.length > 4
			);
	}, []);

	const deleteArtwork = (artWork: ArtworkDetailType | undefined) => {
		if (label) {
			if (artWork && userDesign) {
				dispatch(
					deleteUserDesignLabelThunk({
						designId: String(userDesign.id),
						labelId: String(artWork?.id),
					})
				);
			}
		} else {
			if (artWork) dispatch(deleteArtworkDetailRequest(artWork.id));
		}
	};
	return artwork ? (
		<Wrap $error={error}>
			<div className="artwork-card">
				<div className="artwork-image">
					{artwork.original_resized && (
						<img src={artwork.original_resized} alt="" />
					)}
				</div>
				<div className="artwork-details">
					<div className="artwork-details-list">
						{!props.label && artwork.type && (
							<div className="label-lg">
								{t("artworkDetail.printTechnique")}:{" "}
								{t(`printType.label.${artwork.type}`)}
							</div>
						)}
						{artwork?.colors?.length > 0 && (
							<div className="color-swatches">
								<span className="label-lg">{t("artworkDetail.color")}:</span>
								{artwork.colors?.map((color) => (
									<div
										title={color.toUpperCase()}
										key={color}
										style={{
											backgroundColor: color,
										}}
										className="swatch"
									></div>
								))}
							</div>
						)}
						<div className="label-lg">
							{t("artworkDetail.size")}: {artwork.width_cm} x{" "}
							{artwork.height_cm} cm
						</div>
					</div>
					<div className="artwork-actions">
						<Button
							style={ButtonEnum.link}
							onClick={() => deleteArtwork(props.artwork)}
						>
							{t("artworkDetail.delete")}
						</Button>
					</div>
				</div>
			</div>
			{error && (
				<Alert type={AlertEnum.error} full>
					{t("artworkDetail.error", { type: artwork.type })}
				</Alert>
			)}
		</Wrap>
	) : (
		<LoadingItem />
	);
};

export default ArtworkDetail;
