import styled from "styled-components";

export const Wrap = styled.div<{ $show?: boolean; $click?: boolean }>`
	cursor: ${(props) => (props.$show && props.$click ? "pointer" : "inherit")};
	fill: transparent;
	opacity: ${(props) => (props.$show ? "1" : "0")};
	width: 100%;
	height: 100%;
	position: absolute;
	#placeholder {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
		border-radius: 100%;
		padding: 4px;
		svg {
			width: 20px;
			height: 20px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	svg {
		width: 100%;
		height: 100%;
	}
`;
