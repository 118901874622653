import styled from "styled-components";

export const Wrap = styled.div`
	.artwork-card {
		display: flex;
		align-items: start;
		box-sizing: border-box;
		max-width: 100%;
		width: 100%;

		gap: 16px;
	}
	.artwork-image {
		background: var(--gray20);

		img {
			width: 100px;
		}
	}
	.artwork-details {
		width: 100%;
		display: flex;

		align-items: start;
		justify-content: space-between;
		.artwork-details-list {
			display: flex;
			flex-direction: column;
			gap: 8px;
			.color-swatches {
				display: flex;
				gap: 4px;
				align-items: center;
				.swatch {
					border-radius: 4px;
					width: 20px;
					height: 20px;
					border: 1px solid #ccc;
				}
			}
		}
	}
`;
