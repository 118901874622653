import React from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getDesignRequest } from "../../store/Design";
import { UserDesign } from "../../beans";

import { emptyPage } from "../../constants/icons";

import DesignPreview from "../DesignPreview";

import Icon from "../Icon";
import LoadingCards from "../LoadingCards";
import { useTranslation } from "react-i18next";
import { Wrap } from "./styles";

type Props = {
	limit?: number;
	minimal?: boolean;
};
const DesignList = (props: Props) => {
	const { minimal = false } = props;
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	// LOCAL STATE
	const designList = useSelector((state: RootState) => state.design.data);

	const designRequest = useSelector((state: RootState) => state.design);
	const isLogged = useSelector((state: RootState) => state.login.logged);

	useEffect(() => {
		dispatch(getDesignRequest(1));
	}, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Wrap className="design-list">
			{!designRequest.loading ? (
				designList.length > 0 ? (
					<div className="design-grid">
						{designList.map((design: UserDesign, k: number) => {
							return (
								<DesignPreview design={design} key={k} minimal={minimal} />
							);
						})}
					</div>
				) : (
					<div className="empty-page">
						<Icon icon={emptyPage} />
						<div className="heading-md">{t("design.noDesignYet")}</div>
						<div className="title-lg">{t("design.designAppearHere")}</div>
					</div>
				)
			) : (
				<LoadingCards />
			)}
		</Wrap>
	);
};

export default DesignList;
