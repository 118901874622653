export const initialProduction_50 = {
  x_small: 5,
  small: 10,
  medium: 15,
  large: 10,
  x_large: 10,
  sample: false,
};

export const initialProduction_100 = {
  x_small: 10,
  small: 20,
  medium: 30,
  large: 25,
  x_large: 15,
  sample: false,
};

export const initialProduction_200 = {
  x_small: 20,
  small: 40,
  medium: 60,
  large: 50,
  x_large: 31,
  sample: false,
};

export const initialProduction_300 = {
  x_small: 30,
  small: 60,
  medium: 90,
  large: 75,
  x_large: 46,
  sample: false,
};

export const initialProduction_sample = {
  x_small: 0,
  small: 0,
  medium: 1,
  large: 0,
  x_large: 0,
  sample: true,
};
