import React from "react";
import { useEffect, useRef, useState } from "react";
import Moveable, { OnDragEnd, OnScaleEnd } from "react-moveable";
import { flushSync } from "react-dom";
import {
	ArtworkDetail,
	CageSize,
	PrintLocation,
	PrintLocationEnum,
	PrintTypeEnum,
} from "../../beans";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import { patchUserDesignPrintRequest } from "../../store/UserDesign";

import {
	calclulatePreviewImageTranslation,
	getDOMMatrixScale,
	convertToCm,
	convertToPx,
} from "../../utils/sizes";

import { Wrap } from "./styles";
import {
	MAXIMUM_DTF_HEIGHT_SIZE_CM,
	MAXIMUM_DTF_WIDTH_SIZE_CM,
	MAXIMUM_EMBROIDERY_AREA,
	MAXIMUM_EMBROIDERY_SIZE_CM,
	MAXIMUM_HEIGHT_SIZE_CM,
	MAXIMUM_SCREEN_PRINT_HEIGHT_SIZE_CM,
	MAXIMUM_SCREEN_PRINT_WIDTH_SIZE_CM,
	MAXIMUM_WIDTH_SIZE_CM,
	MINIMUM_SIZE_CM,
} from "../../constants/cages";
import { loadingLogo } from "../../constants/icons";
import Icon from "../Icon";
import { checkFileExistence } from "../../utils/check";
import { cdn_url } from "../../constants/endpoints";

type Props = {
	currentArtwork: ArtworkDetail;
	children?: React.ReactNode;
	printLocation: PrintLocation;
	draggable?: boolean;
	itemId?: string;
};
const Artwork = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();

	// PROPS
	const { currentArtwork, printLocation, draggable, itemId } = props;

	// REF
	const targetRef = useRef<HTMLImageElement>(null);
	const moveableRef = useRef<Moveable>(null);

	// ROOT STATE

	const userDesignState = useSelector((state: RootState) => state.userDesign);
	const userDesign = userDesignState.data;
	const cages = useSelector((state: RootState) => state.ui.cages) as CageSize[];

	// LOCAL STATE
	const [cageWidth, setCageWidth] = useState<number | undefined>(undefined);
	const [cageHeight, setCageHeight] = useState<number | undefined>(undefined);
	const [maskId, setMaskId] = useState<string | null>();

	const getMaskId = async () => {
		const maskUrl = `${cdn_url}/mask/${itemId}_${printLocation.placement}.svg`;

		// dios perdoname por esto codigo
		// TODO define cages on db
		const maskedItems = [
			"014_front_center",
			"014_full_back",
			"014_hoodie_front",
			"015_full_back",
			"016_front_center",
			"016_full_back",
			"016_hoodie_front",
			"017_full_back",
			"018_front_center",
			"018_full_back",
			"018_hoodie_front",
			"019_full_back",
			"2222_full_center",
		];

		const hasMask = maskedItems.find(
			(item) => item === `${itemId}_${printLocation.placement}`
		);

		if (hasMask) {
			const response = await checkFileExistence(maskUrl);
			const mask = response ? maskUrl : null;
			setMaskId(mask);
		} else {
			setMaskId(null);
		}
	};

	const getMaxSizeWithinArea = (target: HTMLElement) => {
		const width = target.getBoundingClientRect().width;
		const height = target.getBoundingClientRect().height;
		const maxArea = Math.pow(
			convertToPx(
				cageWidth,
				printLocation.real_width_cm,
				Math.sqrt(MAXIMUM_EMBROIDERY_AREA)
			),
			2
		);

		const aspectRatio = width / height;
		let maxWidth = Math.sqrt(maxArea * aspectRatio);
		let maxHeight = maxWidth / aspectRatio;

		if (maxWidth * maxHeight > maxArea) {
			maxHeight = Math.sqrt(maxArea / aspectRatio);
			maxWidth = maxHeight * aspectRatio;
		}

		return [maxWidth, maxHeight];
	};

	useEffect(() => {
		getMaskId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cageWidth]);

	// update moveableRef on resize
	useEffect(() => {
		const handleResize = () => {
			moveableRef.current?.updateSelectors();
			moveableRef.current?.updateRect();
		};

		window.addEventListener("resize", handleResize);

		getMaskId();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// update moveableRef on change artwork
	useEffect(() => {
		if (moveableRef.current) {
			moveableRef.current?.updateSelectors();
			moveableRef.current?.updateRect();
		}
	}, [currentArtwork]);

	// save artwork on load
	useEffect(() => {
		if (moveableRef.current) {
			moveableRef.current?.updateSelectors();
			moveableRef.current?.updateRect();
			setTimeout(() => {
				saveOnLoadArtwork(targetRef.current);
			}, 300);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moveableRef.current, cageWidth]);

	// set cage sizes
	useEffect(() => {
		const cage = cages.find(
			(cage: CageSize) => Number(cage.id) === currentArtwork.id
		);
		getMaskId();

		if (cage) {
			setCageWidth(cage ? Number(cage.width) : undefined);
			setCageHeight(cage ? Number(cage.height) : undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cages]);

	const getMaxSizesFromPrintType = (printType: PrintTypeEnum) => {
		let sizes: [number, number] = [0, 0];
		switch (printType) {
			case PrintTypeEnum.embroidery:
				sizes = [MAXIMUM_EMBROIDERY_SIZE_CM, MAXIMUM_EMBROIDERY_SIZE_CM];
				break;
			case PrintTypeEnum.dtf:
				sizes = [MAXIMUM_DTF_WIDTH_SIZE_CM, MAXIMUM_DTF_HEIGHT_SIZE_CM];
				break;
			case PrintTypeEnum.screen_print:
				sizes = [
					MAXIMUM_SCREEN_PRINT_WIDTH_SIZE_CM,
					MAXIMUM_SCREEN_PRINT_HEIGHT_SIZE_CM,
				];
				break;
			default:
				return [MAXIMUM_WIDTH_SIZE_CM, MAXIMUM_HEIGHT_SIZE_CM];
		}
		const maxWidthPrintSize = convertToPx(
			cageWidth,
			printLocation.real_width_cm,
			sizes[0]
		);
		const maxHeightPrintSize = convertToPx(
			cageHeight,
			printLocation.real_height_cm,
			sizes[1]
		);
		return [maxWidthPrintSize, maxHeightPrintSize];
	};

	//// SAVE ON LOAD /////
	const saveOnLoadArtwork = (target: HTMLImageElement | null) => {
		if (!cageWidth || !cageHeight || !target) return;

		if (currentArtwork.position_x === null && currentArtwork.width_cm === 0) {
			// retreive scale from transform
			const matrix = new DOMMatrix(target.style.transform);
			const s = getDOMMatrixScale(matrix);

			// retreive px dimensions
			let targetWidth = target.getBoundingClientRect().width;
			let targetHeight = target.getBoundingClientRect().height;

			// calculate cm dimensions (default max 10CM for longes side)
			let width_cm = 0;
			let height_cm = 0;

			// embroidery needs MaxArea check
			if (
				currentArtwork.type &&
				currentArtwork.type === PrintTypeEnum.embroidery
			) {
				const maxSizes = getMaxSizeWithinArea(target as HTMLElement);
				targetWidth = maxSizes[0];
				targetHeight = maxSizes[1];
			}

			// check longest side to calculate width and height
			if (targetWidth > targetHeight) {
				width_cm = 10;
				height_cm = 10 * (targetHeight / targetWidth);
			} else {
				height_cm = 10;
				width_cm = 10 * (targetWidth / targetHeight);
			}

			const position_x = (Number(printLocation.real_width_cm) - width_cm) / 2;
			const position_y = (Number(printLocation.real_height_cm) - height_cm) / 2;

			if (width_cm > 0 && height_cm > 0) {
				dispatch(
					patchUserDesignPrintRequest({
						designId: userDesign?.id?.toString() || "",
						printId: currentArtwork.id.toString(),
						payload: {
							position_x: position_x,
							position_y: position_y,
							scale: Number(s),
							width_cm: width_cm,
							height_cm: height_cm,
						},
					})
				);
			}
		}
	};

	//// SAVE ON DRAG /////
	const saveDragArtWork = (event: OnDragEnd) => {
		if (!userDesign) return;

		// update the posiion attributes
		const target = event.target;

		// update the posiion attributes
		const tx = new DOMMatrix((target as HTMLElement).style.transform).e; //TODO create a decent bean for x y matrix coordinates
		const ty = new DOMMatrix((target as HTMLElement).style.transform).f; //TODO create a decent bean for x y matrix coordinates

		const position_x_cm = convertToCm(
			Number(cageWidth),
			printLocation.real_width_cm,
			tx
		);

		const position_y_cm = convertToCm(
			Number(cageHeight),
			printLocation.real_height_cm,
			ty
		);

		dispatch(
			patchUserDesignPrintRequest({
				designId: userDesign?.id?.toString(),
				printId: currentArtwork.id.toString(),
				payload: {
					position_x: position_x_cm,
					position_y: position_y_cm,
				},
			})
		);
	};

	//// SAVE ON SCALE /////
	const saveScaleArtWork = (event: OnScaleEnd) => {
		if (!userDesign) return;

		const target = event.target;
		const moveableCoordinates = moveableRef.current?.getRect();

		if (moveableCoordinates && cageWidth) {
			const position_x_cm = convertToCm(
				Number(cageWidth),
				printLocation.real_width_cm,
				moveableCoordinates.left
			);

			const position_y_cm = convertToCm(
				Number(cageWidth),
				printLocation.real_width_cm,
				moveableCoordinates.top
			);
			const width_cm = convertToCm(
				Number(cageWidth),
				printLocation.real_width_cm,
				target.getBoundingClientRect().width
			);

			const height_cm = convertToCm(
				Number(cageWidth),
				printLocation.real_width_cm,
				target.getBoundingClientRect().height
			);

			dispatch(
				patchUserDesignPrintRequest({
					designId: userDesign?.id?.toString(),
					printId: currentArtwork.id.toString(),
					payload: {
						width_cm: width_cm,
						height_cm: height_cm,
						position_x: position_x_cm,
						position_y: position_y_cm,
					},
				})
			);
		}
	};

	return (
		<Wrap $draggable={draggable} $maskId={maskId} className={`${maskId}`}>
			{draggable && (
				<Moveable
					key={`moveable_comp_${currentArtwork.id}`}
					flushSync={flushSync}
					ref={moveableRef}
					origin={false}
					target={targetRef}
					useAccuratePosition={true}
					snappable={true}
					snapDirections={{ middle: true, center: true }}
					draggable={true}
					edgeDraggable={false}
					throttleDrag={0}
					scalable={printLocation.placement !== PrintLocationEnum.front_left}
					throttleScale={0}
					keepRatio={true}
					bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: "css" }}
					renderDirections={["nw", "ne", "se", "sw"]}
					verticalGuidelines={[cageWidth ? cageWidth / 2 : 0]}
					horizontalGuidelines={[cageHeight ? cageHeight / 2 : 0]}
					onRender={(e) => {
						e.target.style.transform = e.transformObject.transform;
					}}
					onDrag={({ target, transform }) => {
						target.style.transform = transform;
					}}
					onDragEnd={(e) => {
						saveDragArtWork(e);
					}}
					onScale={({ target, transform }) => {
						target.style.transform = transform;
					}}
					onScaleStart={({
						target,
						setFixedDirection,
						setMinScaleSize,
						setMaxScaleSize,
					}) => {
						if (cageWidth) {
							const minPrintSize = convertToPx(
								cageWidth,
								printLocation.real_width_cm,
								MINIMUM_SIZE_CM
							);

							//EMBROIDERY
							if (
								currentArtwork.type &&
								currentArtwork.type === PrintTypeEnum.embroidery
							) {
								setMaxScaleSize(getMaxSizeWithinArea(target as HTMLElement));
							} else {
								setMaxScaleSize(
									getMaxSizesFromPrintType(currentArtwork.type as PrintTypeEnum)
								);
							}

							setFixedDirection([0, 0]);
							setMinScaleSize([minPrintSize, minPrintSize]);
						}
					}}
					onScaleEnd={(e) => {
						saveScaleArtWork(e);
					}}
				/>
			)}
			{cageWidth ? (
				<img
					className="artwork-img"
					ref={targetRef}
					id={
						draggable ? `moveable_${currentArtwork.id}` : `${currentArtwork.id}`
					}
					src={currentArtwork.original_resized} // TODO select from print_placement
					alt=""
					style={{
						transform: calclulatePreviewImageTranslation(
							currentArtwork,
							printLocation,
							cageWidth
						),
						width:
							currentArtwork.width_cm && cageWidth !== undefined
								? cageWidth !== undefined
									? convertToPx(
											cageWidth,
											printLocation.real_width_cm,
											currentArtwork.width_cm
										)
									: ""
								: "",
						height:
							currentArtwork.height_cm && cageHeight !== undefined
								? convertToPx(
										cageWidth ?? 0,
										printLocation.real_width_cm,
										currentArtwork.height_cm
									)
								: "",
					}}
				/>
			) : (
				<div className="loading-artwork">
					<Icon icon={loadingLogo} />
				</div>
			)}
		</Wrap>
	);
};

export default Artwork;
