export enum ButtonEnum {
	primary = "primary",
	secondary = "secondary",
	tertiary = "tertiary",
	danger = "danger",
	icon = "icon",
	link = "link",
	menu = "menu",
}

export enum ButtonTypeEnum {
	button = "button",
	submit = "submit",
}

export enum AlertEnum {
	error = "error",
	info = "info",
	success = "success",
	warning = "warning",
}

export enum PrintPlacement {
	front_center = 1,
	full_back = 2,
	left_center = 3,
	cap_front = 4,
	full_center = 10,
}

export enum ActionCardEnum {
	simple = "simple",
	expanded = "expanded",
}

// TODO define all status
export enum OrderStatusEnum {
	created = "created",
	payment_made = "payment made",
}
// TODO define all status
export enum ProductionStatusEnum {
	ordered = "ordered",
}

export enum ProductionTimeEnum {
	sample = "3",
	production = "5",
}

export enum PaymentStatusEnum {
	created = "created",
	succeeded = "succeeded",
	payment_failed = "payment_failed",
	amount_capturable_updated = "amount_capturable_updated",
	canceled = "canceled",
	requires_ = "requires_",
	partially = "partially",
}

export enum PrintTypeEnum {
	embroidery = "embroidery",
	dtf = "dtf",
	screen_print = "screen_print",
	label = "label",
}

export enum PrintLocationEnum {
	front_center = "front_center",
	full_center = "full_center",
	front_left = "front_left",
	full_back = "full_back",
	cap_front = "cap_front",
}

export enum SwatchEnum {
	medium = "medium",
	large = "large",
	small = "small",
}

export enum TabEnum {
	fabric = "fabric",
	print = "print",
	label = "label",
	summary = "summary",
}

export enum ModalEnum {
	registration = "registration",
	login = "login",
	error = "error",
}

export type ModalActionButton = {
	style: ButtonEnum;
	onClick: () => void;
	label: string;
};

export type ArtworkDetail = {
	id: number;
	original_resized: string | undefined;
	quality?: string;
	type: PrintTypeEnum | null;
	location: string | undefined;
	size?: string;
	number_of_colors?: string;
	position_x: number;
	position_y: number;
	scale: number;
	width_cm: number;
	height_cm: number;
	colors?: string[];
};

export type Billing = {
	billing_id?: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	company_name: string;
	vat_number: string;
	address_line_1: string;
	address_line_2: string;
	postcode: string;
	province: string;
	city: string;
	country: string;
};

export type CageSize = {
	id: number;
	width: string;
	height: string;
	conversion_unit: number;
};

export type Country = {
	iso2: string;
	country: string;
};

export type Color = {
	name: string;
	hex: string;
};

export type ColouredFabric = {
	id: number;
	color: Color;
	image_url: string;
};

export type Design = UserDesign[];

export type Fabrics = {
	name: string;
	code: string;
	composition: string;
	weight_gsm: number;
	description: string;
	fabric_image_url: string;
	coloredfabric_set: Fabric[];
};

export type Fabric = {
	image_url: string | undefined;
	id: number;
	color: Color;
	url: string;
	fabric?: string;
};

export type InfoBox = {
	key: string;
	title: string;
	content: HTMLAllCollection | string;
	image: string;
};

export type Item = {
	id: number;
	name: string;
	code: string;
	description: string | null;
	default_render?: string;
};

export type ItemRender = {
	id: number;
	view: string; //( TODO: enum view types)
	image: string;
	default: boolean;
	item_fabric: number;
	print_locations: string[];
};

export type Label = {
	id: number;
	session_key: string;
	name: string;
	image: string;
	user: User; // updated user type
	pricing: number;
};

export type Login = {
	key: string;
};

export type Order = {
	id: number;
	orderitem_set: OrderItem[];
	total_amount: string;
	status: OrderStatusEnum;
};

export type OrderList = {
	id: number;
	status: OrderStatusEnum;
	order_placed_date: string;
	estimated_delivery_date: string;
	orderitems: OrderItem[];
	production_status: PaymentStatusEnum;
	total_amount: string;
};

export type OrderListItem = {
	design: UserDesign;
	production: Production;
};

// TODO fix types
export type OrderSummary = {
	products: OrderItem[];
	shipping: string;
	subtotal: string;
	costs?: {
		designs: UserDesign[];
		subtotal: number;
		shipping: string;
		vat: number;
		coupon: {
			total_before: string;
			coupon_percent: string;
			amount_discount: string;
			code: string;
			total_after: string;
		};
		total: number;
	};
};

export type OrderConfirmation = {
	id: number;
	shipping: number;
	billing: number;
	design_ids: number[];
	payment: Payment[];
	orderitems: OrderItem[];
};

export type OrderItem = {
	id: number;
	design: UserDesign;
	production: Production;
};

export type Payment = {
	id: number;
	payment_intent_id: string;
	total_amount: string;
	currency: string;
	payment_method: string;
	status: string;
	card_suffix: string;
	created_at: string;
	updated_at: string;
	order: number;
};

export type PrintType = {
	id: string;
	type: string;
	label: string;
	description: string;
};

export type PrintLocationPlacement =
	| "full_center"
	| "front_center"
	| "front_left"
	| "full_back"
	| "cap_front"
	| "puffer_front"
	| "hoodie_front"
	| "knit_front";

export type PrintLocation = {
	id: string;
	placement: PrintLocationPlacement;
	print_area_width: number;
	print_area_height: number;
	print_area_top: number;
	print_area_left: number;
	real_height_cm: number;
	real_width_cm: number;
	types_available: string[];
};

export type ProductionDetail = {
	units: Units[];
	productionTime: string;
};

export type Production = {
	id: number;
	design: number;
	sizes: ProductionSize;
	sample: boolean;
	highest_cost?: number;
	current_cost?: number;
};

export type ProductionSize = {
	x_small?: number;
	small?: number;
	medium?: number;
	large?: number;
	x_large?: number;
};

export type ProductionRange = {
	item_fabric_id: number;
	item_lowest_cost: string;
	production_range: string;
	maximum: number;
	minimum: number;
	total: number;
	unit_total: number;
};

export type ProductionRangeAvailability = {
	id: number;
	min: number;
	max: number;
	x_small: boolean;
	small: boolean;
	medium: boolean;
	large: boolean;
	x_large: boolean;
};

export type StripePaymentType =
	| "payment"
	| "setup"
	| "subscription"
	| undefined;

export type Shipping = {
	shipping_id?: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	company_name?: string;
	vat_number?: string;
	address_line_1: string;
	address_line_2: string;
	postcode: string;
	province: string;
	city: string;
	country: string;
	fabbric_warehouse?: boolean;
	tracking_number?: string;
	status?: string;
	same_as_billing: boolean;
};

export type Units = {
	from: number;
	cost: number;
};

export type User = {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	phone: string;
};

export type UserDesignProductionPayment = {
	payment: number;
	status: PaymentStatusEnum;
};

export type UserDesignProduction = {
	order: number;
	order_created: boolean;
	orderitem: number;
	production_id: number;
	payments: UserDesignProductionPayment[];
};

export type UserDesign = {
	id: number;
	design_id?: number;
	user: number;
	session_key: string;
	name: string;
	item_fabric: {
		id: string;
		item: Item;
		col_fabric: Fabric;
	};
	item_render_front: ItemRender;
	item_render_back: ItemRender;
	print_set: ArtworkDetail[];
	production_order_details: {
		productions: UserDesignProduction[];
		production_without_order: number;
	};
	label: ArtworkDetail | null;
	locked: boolean;
};

export type UserDesignCostBreakdown = {
	item: string;
	fabric: string;
	item_cost: number;
	prints: UserDesignPrintCostBreakdown[];
};

export type UserDesignPrintCostBreakdown = {
	location: string;
	technique: string;
	cost: number;
};

export type Tab = {
	id: TabEnum;
	label: string;
	url: string;
	selected?: boolean;
};

export type Icon = {
	path: string;
	width?: string;
	height?: string;
	viewBox: string;
	role?: string;
};

//// FORM TYPES

export type shippingForm = {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	company_name?: string | undefined;
	vat_number?: string;
	address_line_1: string;
	address_line_2?: string;
	postcode: string;
	province: string;
	city: string;
	country: string;
	same_as_billing: NonNullable<boolean>;
};

export type billingForm = {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	company_name?: string;
	vat_number?: string;
	address_line_1: string;
	address_line_2?: string;
	postcode: string;
	province: string;
	city: string;
	country: string;
};

export type SampleProduction = {
	sample: boolean;
	small: number;
	medium: number;
	large: number;
	x_large: number;
};
