import styled from "styled-components";
// import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
	table {
		margin-top: 16px;
		width: 100%;
		border: 1px solid var(--gray30);
		border-radius: 4px;
		text-align: center;
		border-collapse: collapse;
		thead {
			background: var(--gray20);
			th {
				padding: 8px;
				border: 1px solid var(--gray30);
			}
		}
		tbody {
			width: 100%;
			td {
				border: 1px solid var(--gray30);
				padding: 8px;
			}
		}
	}
`;
