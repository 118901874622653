import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Label } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getAuthHeader } from "../../api/auth";

export interface LabelState {
	data: Label[] | [];
	error: null | string;
}

const initialState: LabelState = {
	data: [],
	error: null,
};

export const getLabelThunk = createAsyncThunk(
	"label/request",
	async (payload: { id: string }) => {
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${payload.id}/label/`,
			{
				headers: getAuthHeader(),
			}
		);
		return await response.json();
	}
);

export const labelSlice = createSlice({
	name: "label",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLabelThunk.pending, (state) => {
			state.data = [];
		});
		builder.addCase(getLabelThunk.fulfilled, (state, action) => {
			state.data = action.payload;
		});
		builder.addCase(getLabelThunk.rejected, (state, action) => {
			state.error = action.error?.message || "Unknown error";
		});
	},
});

export default labelSlice.reducer;
