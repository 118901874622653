import styled from "styled-components";

export const Wrap = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	background: var(--white);

	.summary-header {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 4px;
		border-bottom: 1px solid var(--purple30);
		.title {
			padding: 16px 24px;
			width: 100%;
			color: var(--gray130);
		}
	}
	.edit-block {
		display: flex;
		gap: 16px;
		align-items: center;
		width: 100%;

		form {
			width: 100%;
			input {
				width: 100%;
			}
		}
	}
	.manufactured-description {
		position: relative;
		text-indent: 20px;
		&:before {
			position: absolute;
			content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M10.0052%209.58854C9.45267%209.58854%208.92277%209.36905%208.53207%208.97835C8.14137%208.58765%207.92188%208.05774%207.92188%207.50521C7.92188%206.95267%208.14137%206.42277%208.53207%206.03207C8.92277%205.64137%209.45267%205.42188%2010.0052%205.42188C10.5577%205.42188%2011.0876%205.64137%2011.4783%206.03207C11.869%206.42277%2012.0885%206.95267%2012.0885%207.50521C12.0885%207.7788%2012.0347%208.0497%2011.93%208.30247C11.8253%208.55523%2011.6718%208.78489%2011.4783%208.97835C11.2849%209.1718%2011.0552%209.32526%2010.8025%209.42996C10.5497%209.53465%2010.2788%209.58854%2010.0052%209.58854ZM10.0052%201.67188C8.45811%201.67188%206.97438%202.28646%205.88042%203.38042C4.78646%204.47438%204.17188%205.95811%204.17188%207.50521C4.17188%2011.8802%2010.0052%2018.3385%2010.0052%2018.3385C10.0052%2018.3385%2015.8385%2011.8802%2015.8385%207.50521C15.8385%205.95811%2015.224%204.47438%2014.13%203.38042C13.036%202.28646%2011.5523%201.67188%2010.0052%201.67188Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E");
			width: 20px;
			height: 20px;
			display: block;
			text-indent: 0px;
		}
	}

	.artwork-details-summary {
		width: 100%;
	}
`;
