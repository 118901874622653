import React, { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { getAuthHeader } from "../../api/auth";
import { apiUrl } from "../../constants/endpoints";
import LoadingItem from "../LoadingItem";
import Alert from "../Alert";
import { AlertEnum } from "../../beans";

type Props = {
	code: string;
};
const SizeChart = ({ code }: Props) => {
	const [data, setData] = useState<object | null>(null);
	const [measures, setMeasures] = useState<{ [key: string]: number }>({});
	const measuresType = [
		"body_length",
		"half_chest",
		"half_hip",
		"half_waist",
		"sleeve_length",
		"total_length",
	];
	useEffect(() => {
		const fetchSizeCharts = async () => {
			const headers = getAuthHeader();
			const url = `${apiUrl}/designs/sizechart/itemcode/${code}/`;

			const response = await fetch(url, {
				method: "GET", // default, so we can ignore
				headers: headers,
			});
			const data = await response.json();
			setData(data);
			await measuresType.forEach((type) => {
				renderRows(data, type);
			});
		};

		fetchSizeCharts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderRows = (data: object | null, filter: string) => {
		if (data) {
			Object.keys(data).map(function (key: string) {
				if (key.includes(filter) && data[key] != null) {
					const k = key.split("_").pop() || "";
					measures[filter] = { ...measures[filter], [k]: data[key] };
					setMeasures(measures);
				}
			});
		}
	};
	const hasSize = () => {
		return (
			measures.body_length ||
			measures.half_chest ||
			measures.half_hip ||
			measures.half_waist ||
			measures.sleeve_length ||
			measures.total_length
		);
	};

	return (
		<Wrap>
			{data && measures ? (
				<>
					{hasSize() ? (
						<table>
							<thead>
								<tr>
									<th>Size</th>

									{measures.body_length && (
										<th>
											<strong>B</strong> Length (cm)
										</th>
									)}
									{measures.half_chest && (
										<th>
											<strong>A</strong> Half Chest (cm)
										</th>
									)}
									{measures.half_hip && (
										<th>
											<strong>A</strong> Half Hip (cm)
										</th>
									)}

									{measures.half_waist && (
										<th>
											<strong>C</strong> Half waist (cm)
										</th>
									)}
									{measures.sleeve_length && (
										<th>
											<strong>C</strong> Sleeve (cm)
										</th>
									)}
									{measures.total_length && (
										<th>
											<strong>C</strong> Total length (cm)
										</th>
									)}
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>XS</td>
									{measures.body_length && <td>{measures.body_length?.xs}</td>}
									{measures.half_chest && <td>{measures.half_chest?.xs}</td>}
									{measures.half_hip && <td>{measures.half_hip?.xs}</td>}
									{measures.half_waist && <td>{measures.half_waist?.xs}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.xs}</td>
									)}
									{measures.total_length && (
										<td>{measures.total_length?.xs}</td>
									)}
								</tr>
								<tr>
									<td>S</td>
									{measures.body_length && <td>{measures.body_length?.s}</td>}
									{measures.half_chest && <td>{measures.half_chest?.s}</td>}
									{measures.half_hip && <td>{measures.half_hip?.s}</td>}
									{measures.half_waist && <td>{measures.half_waist?.s}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.s}</td>
									)}
									{measures.total_length && <td>{measures.total_length?.s}</td>}
								</tr>
								<tr>
									<td>M</td>
									{measures.body_length && <td>{measures.body_length?.m}</td>}
									{measures.half_chest && <td>{measures.half_chest?.m}</td>}
									{measures.half_hip && <td>{measures.half_hip?.m}</td>}
									{measures.half_waist && <td>{measures.half_waist?.m}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.m}</td>
									)}
									{measures.total_length && <td>{measures.total_length?.m}</td>}
								</tr>
								<tr>
									<td>L</td>
									{measures.body_length && <td>{measures.body_length?.l}</td>}
									{measures.half_chest && <td>{measures.half_chest?.l}</td>}
									{measures.half_hip && <td>{measures.half_hip?.l}</td>}
									{measures.half_waist && <td>{measures.half_waist?.l}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.l}</td>
									)}
									{measures.total_length && <td>{measures.total_length?.l}</td>}
								</tr>
								<tr>
									<td>XL</td>
									{measures.body_length && <td>{measures.body_length?.xl}</td>}
									{measures.half_chest && <td>{measures.half_chest?.xl}</td>}
									{measures.half_hip && <td>{measures.half_hip?.xl}</td>}
									{measures.half_waist && <td>{measures.half_waist?.xl}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.xl}</td>
									)}
									{measures.total_length && (
										<td>{measures.total_length?.xl}</td>
									)}
								</tr>
								<tr>
									<td>XXL</td>
									{measures.body_length && <td>{measures.body_length?.xxl}</td>}
									{measures.half_chest && <td>{measures.half_chest?.xxl}</td>}
									{measures.half_hip && <td>{measures.half_hip?.xxl}</td>}
									{measures.half_waist && <td>{measures.half_waist?.xxl}</td>}
									{measures.sleeve_length && (
										<td>{measures.sleeve_length?.xxl}</td>
									)}
									{measures.total_length && (
										<td>{measures.total_length?.xxl}</td>
									)}
								</tr>
							</tbody>
						</table>
					) : (
						<Alert type={AlertEnum.warning}>No size chart available</Alert>
					)}
				</>
			) : (
				<LoadingItem />
			)}
		</Wrap>
	);
};

export default SizeChart;
