import React from "react";
import { Wrap } from "./styles";
import { MouseEventHandler } from "react";

import Icon from "../Icon";

type Props = {
	id?: string;
	title?: string;
	external?: boolean;
	description?: string;
	disabled?: boolean;
	icon?: { path: string; width: string; height: string; viewBox: string };
	className?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
};
const ActionButton = ({
	id,
	title,
	external = false,
	description,
	disabled,
	icon,
	className,
	onClick,
}: Props) => {
	return (
		<Wrap
			id={id}
			disabled={disabled}
			$external={external}
			onClick={onClick}
			className={className}
		>
			<div className="button-content">
				{icon && <Icon icon={icon} />}
				<div className="button-labels">
					<div className="title-sm title">{title}</div>
					{description && (
						<span className="label-sm description">{description}</span>
					)}
				</div>
			</div>
		</Wrap>
	);
};

export default ActionButton;
