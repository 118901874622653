import React from "react";
import { ButtonEnum, ButtonTypeEnum } from "../../beans";
import Button from "../Button";
import Logo from "../Logo";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { registrationRequest } from "../../store/Registration";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useNavigate } from "react-router";
import { showAccessForm } from "../../store/UI";
import FormContainer from "../FormContainer";
import { routes } from "../../constants/routes";
import { registrationForm, RegistrationSchema } from "../../utils/form";
import { useTranslation } from "react-i18next";
import { sanitizeData } from "../../utils/sanitize";
import GoogleSignup from "../GoogleSignup";

const RegistrationForm = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const login = useSelector((state: RootState) => state.login);
	const registration = useSelector((state: RootState) => state.registration);

	useEffect(() => {
		if (login.logged) {
			navigate(routes.newUser);
		}
	}, [registration, login]); // eslint-disable-line react-hooks/exhaustive-deps

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
	});

	const {
		handleSubmit,
		register,
		formState: { errors, touchedFields },
	} = useForm<registrationForm>({
		resolver: yupResolver(RegistrationSchema),
		defaultValues: formData,
		shouldFocusError: false,
		mode: "onChange",
	});
	const handleOnSubmit = (data: registrationForm) => {
		dispatch(registrationRequest(sanitizeData(data)));
	};

	const handleChangeForm = async (data: registrationForm) => {
		setFormData(data);
	};

	return (
		<Wrap>
			<div className="logo">
				<Logo />
			</div>
			<div className="description">{t("registrationForm.title")}</div>
			<FormContainer>
				<form
					onChange={handleSubmit(handleChangeForm)}
					onSubmit={handleSubmit(handleChangeForm)}
				>
					<div className="form-row">
						<div className="input-row">
							<label className="label-sm" htmlFor="first_name">
								{t("registrationForm.firstName")}
							</label>
							<input
								key="first_name"
								id="first_name"
								aria-label="first_name"
								className={`size-selector-input`}
								type="text"
								{...register("first_name")}
							/>
							{errors["first_name"] && (
								<small className="error-field">
									{t(`${errors["first_name"]?.message}`)}
								</small>
							)}
						</div>
						<div className="input-row">
							<label className="label-sm" htmlFor="last_name">
								{t("registrationForm.lastName")}
							</label>
							<input
								key="last_name"
								id="last_name"
								className={`size-selector-input`}
								type="text"
								{...register("last_name")}
							/>
							{errors["last_name"] && (
								<small className="error-field">
									{t(`${errors["last_name"]?.message}`)}
								</small>
							)}
						</div>
					</div>

					<div className="form-row">
						<div className="input-row">
							<label className="label-sm" htmlFor="email">
								{t("registrationForm.email")}
							</label>
							<input
								key="email"
								id="email"
								className={`size-selector-input`}
								type="text"
								{...register("email")}
							/>
							{errors["email"] && (
								<small className="error-field">
									{t(`${errors["email"]?.message}`)}
								</small>
							)}
						</div>

						<div className="input-row">
							<label className="label-sm" htmlFor="password">
								{t("registrationForm.password")}
							</label>
							<input
								key="password"
								id="password"
								className={`size-selector-input`}
								type="password"
								{...register("password")}
							/>
							{errors["password"] && touchedFields["password"] && (
								<small className="error-field">
									{t(`${errors["password"]?.message}`)}
								</small>
							)}
						</div>
					</div>

					<div className="form-row">
						<Button
							style={ButtonEnum.primary}
							onClick={handleSubmit(handleOnSubmit)}
							loading={registration.loading}
						>
							{t("registrationForm.createAccount")}
						</Button>
					</div>
				</form>
			</FormContainer>
			<GoogleSignup />
			<div
				className="g_id_signin"
				data-type="standard"
				data-size="large"
				data-theme="outline"
				data-text="sign_in_with"
				data-shape="rectangular"
				data-logo_alignment="left"
			></div>

			<div className="description label-lg">
				{t("registrationForm.privacy.agree")}{" "}
				<a
					href="https://fabbric.com/policies/terms-of-service"
					target="_blank"
					rel="noreferrer"
				>
					{t("registrationForm.privacy.terms")}
				</a>{" "}
				{t("registrationForm.privacy.aknowledge")}{" "}
				<a
					href="https://fabbric.com/policies/privacy-policy"
					target="_blank"
					rel="noreferrer"
				>
					{t("registrationForm.privacy.policy")}
				</a>
			</div>
			<div className="description label-lg">
				{t("registrationForm.alreadyAccount")} &nbsp;
				<Button
					type={ButtonTypeEnum.button}
					onClick={() =>
						dispatch(showAccessForm({ login: true, register: false }))
					}
					style={ButtonEnum.link}
				>
					{t("registrationForm.signin")}
				</Button>
			</div>
		</Wrap>
	);
};

export default RegistrationForm;
