import { PrintLocation } from "../beans";

export function translatePrintLocationById(
  locationId: string,
  printLocations: PrintLocation[],
) {
  const location = printLocations.find(
    (location) => location.id === locationId,
  );
  if (location) return location?.placement;
  else {
    return "unkown position";
  }
}

export function getPrintLocationById(
  locationId: string,
  printLocations: PrintLocation[],
) {
  return printLocations.find((location) => location.id === locationId);
}

export function getPrintLocationIdFromName(
  locationPlacement: string,
  printLocations: PrintLocation[],
) {
  const location = printLocations.find(
    (location) => location.placement === locationPlacement,
  );
  if (location) return location.id;
}

export const translateSize = (size: string) => {
  switch (size) {
    case "xx_small":
      return "XXS";
    case "x_small":
      return "XS";
    case "small":
      return "S";
    case "medium":
      return "M";
    case "large":
      return "L";
    case "x_large":
      return "XL";
    case "xx_large":
      return "XXL";
    case "xxx_large":
      return "XXXL";
  }
};
