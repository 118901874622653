import React from "react";
import { ButtonEnum } from "../../beans";
import Button from "../Button";

import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import FormContainer from "../FormContainer";
import { ConfirmPasswordSchema } from "../../utils/form";
import { confirmPasswordRequest } from "../../store/Password";
import { useNavigate } from "react-router";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { sanitizeData } from "../../utils/sanitize";

type Props = {
  uid: string;
  token: string;
};
const ConfirmPasswordForm = (props: Props) => {
  const { uid, token } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const passwordRequestState = useSelector(
    (state: RootState) => state.password,
  );

  const [formData, setFormData] = useState({
    uid: uid,
    token: token,
    new_password1: "",
    new_password2: "",
  } as { new_password1: string; new_password2: string | undefined });

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(ConfirmPasswordSchema),
    defaultValues: formData,
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = (data: {
    new_password1: string;
    new_password2?: string;
  }) => {
    dispatch(confirmPasswordRequest(sanitizeData(data)));
  };

  const handleChangeForm = async (data: {
    new_password1: string;
    new_password2?: string;
  }) => {
    setFormData({
      ...data,
      new_password2: data.new_password2 ?? "",
    });
  };

  return (
    <Wrap>
      <FormContainer>
        <form onChange={handleSubmit(handleChangeForm)}>
          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">Password</label>
              <input
                key="password1"
                className={`size-selector-input`}
                type="password"
                {...register("new_password1")}
              />
              {errors["new_password1"] && (
                <small className="error-field">
                  {touchedFields["new_password1"] &&
                    errors["new_password1"]?.message}
                </small>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">Confirm password</label>
              <input
                key="password2"
                className={`size-selector-input`}
                type="password"
                {...register("new_password2")}
              />
              {errors["new_password2"] && (
                <small className="error-field">
                  {errors["new_password2"]?.message}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <Button
              style={ButtonEnum.primary}
              onClick={handleSubmit(handleOnSubmit)}
              disabled={!isDirty || !isValid}
              loading={passwordRequestState.loading}
            >
              Reset password
            </Button>
          </div>
          <Button
            style={ButtonEnum.tertiary}
            onClick={() => navigate(routes.home)}
            loading={passwordRequestState.loading}
          >
            {t("button.backLogin")}
          </Button>
        </form>
      </FormContainer>
    </Wrap>
  );
};

export default ConfirmPasswordForm;
