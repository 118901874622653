import React from "react";
import { ButtonEnum } from "../../beans";
import { Wrap } from "./styles";

import { useGoogleLogin } from "@react-oauth/google";
import { google } from "../../constants/icons";
import Icon from "../Icon";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { googleLoginRequest } from "../../store/Login";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

type Props = {
	login?: boolean;
};
const GoogleSignup = (props: Props) => {
	const login = props.login;
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const onGoogleSuccess = (codeResponse: { access_token: string }) => {
		dispatch(googleLoginRequest(codeResponse.access_token));
	};

	const googleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => onGoogleSuccess(codeResponse),
		onError: (error) => console.log("Login Failed:", error),
	});

	return (
		<Wrap>
			<Button
				style={ButtonEnum.secondary}
				className="fullwidth"
				onClick={() => googleLogin()}
			>
				<Icon icon={google} class="clean" />
				{login
					? t("registrationForm.signinGoogle")
					: t("registrationForm.signupGoogle")}
			</Button>
		</Wrap>
	);
};

export default GoogleSignup;
