import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{ $empty: boolean }>`
  background: var(--gray20);
  min-height: calc(100vh - var(--headerHeight));
  height: 100%;
  .card-containers {
    padding: 24px 0;
    display: flex;
    gap: 48px;
    height: 100%;

    .empty-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 24px;
      svg {
        path {
          fill: var(--purple100);
        }
      }
    }
    .card {
      .card-container {
        gap: 16px;
      }
    }
    .production-options {
      min-width: ${(props) => (props.$empty ? "100%" : "60%")};
      display: flex;
      flex-direction: column;
      gap: 16px;

      .card-containers-title {
        color: var(--gray130);
      }

      .order-type,
      .order-quantity {
        .order-type-options,
        .order-quantity-options {
          display: flex;
          gap: 16px;
          flex-wrap: wrap;
        }
        .order-type-options {
          .card-content {
            justify-content: center;
            text-align: center;
          }
          .action-card {
            width: calc(50% - 8px);
          }
        }
        .order-quantity-options {
          .action-card {
            width: calc(33% - 16px);
          }
        }
      }

      .order-type-info {
        margin-top: 16px;
      }
      .size-selector-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .price-per-unit-container {
        display: flex;
        justify-content: center;
        height: 100%;
        gap: 16px;
        align-items: center;
        .price,
        .total {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .price-unit,
        .total-unit {
          height: 37px;
          display: flex;
          align-items: center;
        }
      }
    }
    .summary-container {
      min-width: calc(40% - 48px);
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .card-containers {
      gap: 16px;
      .order-quantity {
        .order-quantity-options {
          .action-card {
            width: calc(50% - 16px);
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .card-containers {
      flex-direction: column;
      .production-options {
        width: 100%;
      }
      .order-type,
      .order-quantity {
        .order-type-options,
        .order-quantity-options {
          .action-card {
            width: 100%;
            .card-content {
              padding: 8px;
            }
          }
        }
      }
    }

    .card-header {
      .card-title {
        gap: 16px;
      }
    }
  }
`;
