import styled from "styled-components";
// import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div`
	width: 100%;
	padding: 16px 0;
	.cost-row {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px dotted var(--gray30);
		padding: 4px 0px;
		align-items: center;
		gap: 10px;
	}
`;
