import React from "react";

import { Wrap } from "./styles";

type Props = {
	id?: string;
	item: React.ReactNode;
	full?: boolean;
	children?: React.ReactNode;
};

const DropDown = (props: Props) => {
	const { item, children, id } = props;

	const handleToggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
		const allDropdowns = document.querySelectorAll(".item-dropdown");
		const isOpen = e.currentTarget.classList.contains("open");
		allDropdowns.forEach((element) => {
			element?.classList.remove("open");
		});
		if (!isOpen) {
			e.currentTarget.classList.add("open");
		}
	};
	return (
		<Wrap
			onClick={(e) => handleToggleDropdown(e)}
			className={"item-dropdown"}
			id={id}
		>
			<div className="item-action">{item}</div>
			<div className={"items"}>{children}</div>
		</Wrap>
	);
};

export default DropDown;
