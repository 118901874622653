import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { call, put, select, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { ArtworkDetail } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { getProductionRangeRequest } from "../ProductionRange";
import { showErrorModal } from "../UI";
import { patchUserDesignRequest } from "../UserDesign";
import { getUserDesign } from "../UserDesign/selectors";

export interface ArtworkDetailState {
	data: ArtworkDetail[];
	error: Record<string, unknown>;
	loading: boolean;
	loaded: boolean;
}

const initialState: ArtworkDetailState = {
	data: [],
	error: {},
	loading: false,
	loaded: false,
};

export const artworkDetailSlice = createSlice({
	name: "artworkDetail",
	initialState,
	reducers: {
		remove: (state, action) => {
			state.data.splice(
				state.data.findIndex(
					(artwork) => artwork.location === action.payload.printLocation
				),
				1
			);
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		updateArtworkDetailRequest: (state, _action) => {
			state.data = initialState.data;
			state.error = initialState.error;
			state.loading = true;
		},
		updateArtworkDetailSuccess: (state, action) => {
			state.data.push(action.payload);
			state.loading = false;
			state.loaded = true;
		},
		updateArtworkDetailFailre: (state, action) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		deleteArtworkDetailRequest: (state, _action) => {
			state.data = initialState.data;
			state.error = initialState.error;
			state.loading = true;
		},
		deleteArtworkDetailSuccess: (state, action) => {
			state.data.push(action.payload);
			state.loading = false;
			state.loaded = true;
		},
		deleteArtworkDetailFailre: (state, action) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
	},
});

// Define the saga
function* updateArtworkDetailSaga(
	action: PayloadAction<{ printLocation: string }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	const userDesign = yield select(getUserDesign());

	if (userDesign) {
		const headers = getAuthHeader();
		try {
			const response = yield call(
				fetch,
				`${apiUrl}/designs/userdesigns/${userDesign.id}/print/`,
				{
					method: "POST",
					headers: headers,
					body: JSON.stringify(action.payload),
					credentials: "include",
				}
			);
			const data = yield response.json();

			if (response.ok) {
				yield put(
					patchUserDesignRequest({
						designId: userDesign?.id,
						payload: { print_set: [...userDesign.print_set, data] },
					})
				);
				yield put(updateArtworkDetailSuccess(data));
				yield put(getProductionRangeRequest(userDesign?.id));
			} else {
				yield put(updateArtworkDetailFailre(data));
				yield put(showErrorModal(getErrors(data)));
			}
		} catch (error: unknown) {
			console.error("ERROR UPDATE ARTQWORK", error);
			const errorMessage =
				"The server encountered an unexpected problem. Try again";
			yield put(updateArtworkDetailFailre({ serverError: [errorMessage] }));
			yield put(showErrorModal(errorMessage));
		}
	}
}

function* deleteArtworkDetailSaga(
	action: PayloadAction<{ printLocation: string }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator {
	const userDesign = yield select(getUserDesign());

	try {
		const headers = getAuthHeader();

		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesigns/${userDesign.id}/print/${action.payload}/`,

			{
				method: "DELETE",
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();

		if (response.ok) {
			yield put(
				patchUserDesignRequest({
					designId: userDesign?.id,
					payload: { print_set: [...userDesign.print_set, data] },
				})
			);
			yield put(deleteArtworkDetailSuccess(data));
		} else {
			yield put(deleteArtworkDetailFailre(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		yield put(deleteArtworkDetailFailre(error));
		yield put(showErrorModal(String(error)));
	}
}

// TODO define actions better in bindActionCreators.tsx
export const {
	remove,
	updateArtworkDetailRequest,
	updateArtworkDetailSuccess,
	updateArtworkDetailFailre,
	deleteArtworkDetailRequest,
	deleteArtworkDetailSuccess,
	deleteArtworkDetailFailre,
} = artworkDetailSlice.actions;

export default artworkDetailSlice.reducer;

export function* sagas() {
	yield takeEvery(updateArtworkDetailRequest.type, updateArtworkDetailSaga);
	yield takeEvery(deleteArtworkDetailRequest.type, deleteArtworkDetailSaga);
}
