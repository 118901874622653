import React from "react";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { showFabricInfoBox } from "../../store/UI";
import { AppDispatch } from "../../store";
import { ButtonEnum, Color, SwatchEnum } from "../../beans";
import { info as infoIcon } from "../../constants/icons";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  children?: React.ReactNode;
  size?: SwatchEnum;
  image?: string;
  color?: Color;
  title?: string;
  description?: string;
  selected?: boolean;
  info?: boolean;
  code?: string;
  edit?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
};
const Swatch = (props: Props) => {
  const {
    image,
    info,
    color,
    size,
    description,
    title,
    selected,
    edit,
    code,
    onClick,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Wrap
        className={size ? size : "large"}
        $fabric={image}
        $selected={!!selected}
        $color={color}
        $edit={!!edit}
        id={
          code ? `fabric_${code}` : `fabric_color_${color?.hex.split("#")[1]}`
        }
      >
        <div className="swatch" onClick={onClick}>
          <div className="fabric-image"></div>
        </div>
        {title && <div className="fabric-description label-lg">{title}</div>}
        {info && (
          <div className="fabric-info-button">
            <Button
              style={ButtonEnum.icon}
              onClick={() =>
                dispatch(
                  showFabricInfoBox({
                    active: {
                      title: t("swatch.title"),
                      content: description,
                      image: image,
                    },
                  }),
                )
              }
            >
              <Icon info icon={infoIcon}></Icon>
            </Button>
          </div>
        )}
      </Wrap>
    </>
  );
};

export default Swatch;
