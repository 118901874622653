import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonTypeEnum, ButtonEnum } from "../../beans";
import { phone, externalLink, questionBaloon } from "../../constants/icons";
import Button from "../Button";
import Card from "../Card";
import Icon from "../Icon";
import { Wrap } from "./styles";
const HelpContainer = () => {
	const { t } = useTranslation();

	return (
		<Wrap className="help-container">
			<Card>
				<div>
					<Icon icon={phone} />
				</div>
				<div className="title-sm">
					{t("orderProduction.helpAssistanceTitle")}
				</div>
				<div
					className="label-lg"
					dangerouslySetInnerHTML={{
						__html: t("orderProduction.helpAssistanceDescription"),
					}}
				/>

				<div className="label-lg">
					<Button
						type={ButtonTypeEnum.button}
						style={ButtonEnum.link}
						onClick={() =>
							window.open("https://fabbric.com/pages/demo", "_blank")
						}
					>
						{t("orderProduction.helpAssistanceButton")}
						<Icon icon={externalLink} />
					</Button>
				</div>
			</Card>
			<Card>
				<div>
					<Icon icon={questionBaloon} />
				</div>
				<div className="title-sm">{t("orderProduction.helpAnswerTitle")}</div>
				<div className="label-lg">
					{t("orderProduction.helpAnswerDescription")}
				</div>
				<div className="label-lg">
					<Button
						type={ButtonTypeEnum.button}
						style={ButtonEnum.link}
						onClick={() => {
							window.open(
								"https://help-center.fabbric.com/knowledge",
								"_blank"
							);
						}}
					>
						{t("orderProduction.helpAnswerButton")} <Icon icon={externalLink} />
					</Button>
				</div>
			</Card>
		</Wrap>
	);
};

export default HelpContainer;
