import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.button<{ $external: boolean }>`
	display: flex;
	width: 33%;
	padding: 16px;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	border-radius: 4px;
	border: 0;
	background-color: var(--gray20);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		background-color: var(--gray30);
	}
	.button-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
		svg {
			min-width: 40px;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			background-color: var(--purple100);
			background-color: ${(props) =>
				props.$external ? "var(--gray110);" : "var(--purple100);"};

			padding: 8px;
			path {
				fill: white;
			}
		}

		.button-labels {
			.title {
				color: black;
			}
			text-align: left;
		}
	}
	@media only screen and (max-width: ${breakpoints.desktop}px) {
		width: 33%;
		align-items: center;
		justify-content: center;
		.button-content {
			flex-direction: column;
			align-items: center;
			.button-labels {
				text-align: center;
			}
		}
		.description {
			display: none;
		}
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		align-items: start;
	}
`;
