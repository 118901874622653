import styled from "styled-components";

export const Wrap = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
	position: relative;
	justify-content: end;
	display: flex;
	align-items: center;
	gap: 8px;
	white-space: nowrap;
	height: 50px;
	.lang {
		text-transform: uppercase;
		display: inline-flex;
		color: var(--gray90);
		font-family: "Helvetica Bold";
	}

	&:hover {
		.lang-actions {
			display: flex;
		}
	}
`;
