import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  background: var(--gray20);
  min-height: calc(100vh - var(--headerHeight));
  height: 100%;
  padding-top: 24px;
  .order-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title-sm {
      color: var(--gray130);
    }
  }
  .order-details-container {
    padding: 24px 0;
    display: flex;
    gap: 48px;
    height: 100%;
    .order-details-content {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .order-details {
      display: flex;
      width: 100%;
      gap: 24px;
      div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .list-details {
          .title-sm {
            color: var(--gray130);
          }
        }
      }
      .payment {
        .title-sm {
          color: var(--gray130);
        }
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    .order-summary {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .order-details-container {
      flex-direction: column;

      > div:first-of-type {
        width: 100%;
      }

      .order-details {
        flex-direction: column;
      }
      .order-summary {
        width: 100%;
      }
    }
  }
`;
