import styled from "styled-components";
import { loading, rotate } from "../../design-system/animations";

export const Wrap = styled.div`
	width: 100%;
	max-height: 100%;
	height: 100%;
	min-height: 300px;
	position: relative;
	z-index: 99;

	.card {
		width: 100%;
		height: 100%;
		max-height: 100%;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		svg {
			animation: ${rotate} 2s linear infinite;
			fill: #7745ff;
			width: 20px;
			height: 20px;
		}
	}
	.animate {
		color: transparent;
		background: linear-gradient(
			100deg,
			var(--gray20) 30%,
			var(--gray30) 50%,
			var(--gray20) 70%
		);
		background-size: 400%;
		background-position: 100% 50%;
		animation: ${loading} 1.8s ease-in-out infinite;
	}
	&.small {
		min-height: 100px;
		.card {
			min-height: 100px;
		}
	}
`;
