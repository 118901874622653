import React from "react";
import { useSelector } from "react-redux";
import { ArtworkDetail, PrintLocationEnum, UserDesign } from "../../beans";
import { RootState } from "../../store";
import ArtworkCage from "../ArtworkCage";
import { Wrap } from "./styles";
import Artwork from "../Artwork";
import { getPrintLoactionById } from "../../utils/printLocation";
import { getPrintLocationById } from "../../utils/translate";
import ProductImage from "../ProductImage";
import { useTranslation } from "react-i18next";
import ArtworkArea from "../ArtworkArea";

type Props = {
  artwork?: ArtworkDetail;
  design?: UserDesign;
  size?: string;
};

const ArtworkPreview = (props: Props) => {
  const { artwork, design, size } = props;
  const { t } = useTranslation();

  // SELECTORS
  const userDesignState = useSelector(
    (state: RootState) => state.userDesign.data,
  );
  const printLocations = useSelector(
    (state: RootState) => state.printLocations.data,
  );
  const selectedPrintLocation = useSelector(
    (state: RootState) => state.printLocations.selected,
  );
  const itemRenderList = useSelector(
    (state: RootState) => state.itemsRenderList.data,
  );

  const userDesign = design ? design : userDesignState;

  const initialPrintLocationId = printLocations.findIndex(function (location) {
    return location.id === artwork?.location;
  });

  const renderArtwork = () => {
    const image = getArtworkItemImage();
    return (
      artwork && (
        <div className="product-preview">
          {image && <ProductImage src={image} size={size} />}
          <ArtworkArea
            className={`artwork-area ${printLocations[initialPrintLocationId]?.placement} item_${design?.item_fabric.item.code}`}
          >
            {selectedPrintLocation &&
              printLocations[initialPrintLocationId] && (
                <ArtworkCage
                  printLocation={printLocations[initialPrintLocationId]}
                  htmlId={artwork.id}
                />
              )}

            {printLocations.length > 0 && artwork?.location && (
              <Artwork
                itemId={design?.item_fabric.item.code}
                currentArtwork={artwork}
                printLocation={getPrintLoactionById(
                  artwork?.location,
                  printLocations,
                )}
              />
            )}
          </ArtworkArea>
          <div className="print-type">
            {t(`printType.label.${artwork?.type}`)}
          </div>
        </div>
      )
    );
  };

  const getArtworkItemImage = () => {
    if (itemRenderList && itemRenderList?.length > 0 && !design) {
      return itemRenderList[initialPrintLocationId ? initialPrintLocationId : 0]
        .image;
    }
    if (artwork?.location) {
      const printLocation = getPrintLocationById(
        artwork.location,
        printLocations,
      );
      return artwork && printLocation?.placement === PrintLocationEnum.full_back
        ? design?.item_render_back?.image
        : design?.item_render_front?.image;
    } else {
      // console.error("error on Render Artwork");
    }
  };

  return userDesign && artwork && printLocations ? (
    <Wrap className={size}>{renderArtwork()}</Wrap>
  ) : (
    <Wrap className={props.size}>
      <div className="product-preview">
        {itemRenderList?.length ? (
          userDesign?.item_render_front.image && (
            <ProductImage
              src={userDesign?.item_render_front.image}
              alt=""
              draggable={false}
              size={size}
            />
          )
        ) : (
          <ProductImage
            src={userDesign?.item_render_front?.image || ""}
            size={size}
          />
        )}
      </div>
    </Wrap>
  );
};

export default ArtworkPreview;
