import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { authUrl } from "../../constants/endpoints";
import { getAuthHeader } from "../../api/auth";
import { User } from "../../beans";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";

export interface UserState {
	data: User | null;
	error: null | string | object;
	loading: boolean;
	loaded: boolean;
}

const initialState: UserState = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

// DEFINE SAGAS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* userSaga(): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${authUrl}/user/`, {
			headers: headers,
			credentials: "include",
		});

		const data = yield response.json();
		if (response.ok) {
			yield put(userSuccess(data));
		} else {
			yield put(userFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ERROR USER", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(userFailure({ serverError: [errorMessage] }));
		yield put(showErrorModal(errorMessage));
	}
}

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		userRequest: (state) => {
			state.error = initialState.error;
			state.loading = true;
		},
		userSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		userFailure: (state, action: PayloadAction<string | object>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
	},
});

// TODO define actions better in bindActionCreators.tsx
export const { userRequest, userSuccess, userFailure } = userSlice.actions;

export default userSlice.reducer;

export function* sagas() {
	yield takeEvery(userRequest.type, userSaga);
}
