import i18n from "../i18n/config";
import { getCookie } from "../utils/cookies";

export const getAuthHeader = () => {
	const token = getCookie("token");

	let headers: { [key: string]: string } = {
		Accept: "application/json",
		"Content-Type": "application/json",
		"Accept-Language": i18n.language,
	};
	if (token) headers = { ...headers, Authorization: "Token " + token };
	return headers;
};
