import React from "react";
import { ButtonEnum, OrderList, OrderListItem } from "../../beans";
import { Wrap } from "./styles";
import Button from "../Button";
import ProductionDesigns from "../ProductionDesigns";
import { dateFormat } from "../../utils/format";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

type Props = {
  order: OrderList;
  minimal?: boolean;
};
const OrderDetailsCard = (props: Props) => {
  const { order, minimal = false } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrap>
      <div className="order-card">
        <div className="order-header">
          <div className="order-details">
            <div className="order-placed">
              <div className="label-sm">
                {t("orderDetailsCard.orderPlaced")}
              </div>
              <div className="title-sm">
                {dateFormat(order.order_placed_date)}
              </div>
            </div>
            <div className="order-total">
              <div className="label-sm"> {t("orderDetailsCard.total")}</div>
              <div className="title-sm">{order?.total_amount} €</div>
            </div>
          </div>
          <div className="order-actions">
            <div className="label-sm">
              {" "}
              {t("orderDetailsCard.orderNumber")}: {order.id}
            </div>
            <Button
              style={ButtonEnum.link}
              onClick={() => navigate(`/order/${order.id}/completed/`)}
            >
              {t("orderDetailsCard.viewOrderSummary")}
            </Button>
          </div>
        </div>
        {!minimal && (
          <div className="order-production-details">
            {order.orderitems &&
              !minimal &&
              order.orderitems.map((item: OrderListItem, k: number) => {
                return (
                  <>
                    <ProductionDesigns
                      userDesign={item.design}
                      production={item.production}
                      key={`production_${item.design.id}_${k}`}
                    />
                  </>
                );
              })}
            <div className="order-production-actions">
              <div>
                <div className="title-lg">
                  {t("orderDetailsCard.estimatedDelivery")}{" "}
                  {dateFormat(order.estimated_delivery_date)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrap>
  );
};

export default OrderDetailsCard;
