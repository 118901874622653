import * as yup from "yup";
import { phoneRegExp } from "./regex";

// RESGISTRATION SCHEMA
export const RegistrationSchema = yup.object({
	first_name: yup.string().required("form.error.required").label("First name"),
	last_name: yup.string().required("form.error.required").label("Last name"),
	email: yup
		.string()
		.email("form.error.email")
		.required("form.error.required")
		.label("Email"),
	password: yup
		.string()
		.required("form.error.required")
		.label("Password")
		.min(8, "form.error.passwordShort"),
});

// LOGIN SCHEMA
export const loginSchema = yup.object({
	email: yup
		.string()
		.trim()
		.email("form.error.email")
		.required("form.error.required")
		.label("Email"),
	password: yup.string().required("form.error.required"),
});

// PASSWORD SCHEMA
export const passwordSchema = yup.object({
	email: yup
		.string()
		.trim()
		.email("form.error.email")
		.required("form.error.required")
		.label("Email"),
});

// SHIPPING SCHEMA
export const shippingSchema = yup.object({
	first_name: yup.string().required("form.error.required").label("First name"),
	last_name: yup.string().required("form.error.required").label("Last name"),
	email: yup
		.string()
		.email("form.error.email")
		.required("form.error.required")
		.label("Email"),
	phone: yup
		.string()
		.required("form.error.required")
		.label("Phone number")
		.matches(phoneRegExp, "form.error.phone"),
	company_name: yup.string(),
	vat_number: yup.string(),
	address_line_1: yup.string().required("form.error.required").label("Address"),
	address_line_2: yup.string().label("Address"),
	postcode: yup.string().required("form.error.required").label("Postcode"),
	province: yup.string().required("form.error.required").label("Province"),
	city: yup.string().required("form.error.required").label("City"),
	country: yup.string().required("form.error.required").label("Country"),
	same_as_billing: yup.boolean().required("form.error.required"),
});

// BILLING SCHEMA
export const billingSchema = yup.object({
	first_name: yup.string().required("form.error.required").label("First name"),
	last_name: yup.string().required("form.error.required").label("Last name"),
	email: yup
		.string()
		.email("form.error.email")
		.required("form.error.required")
		.label("Email"),
	phone: yup
		.string()
		.required("form.error.required")
		.label("Phone number")
		.matches(phoneRegExp, "form.error.phone"),
	company_name: yup.string(),
	vat_number: yup.string(),
	address_line_1: yup.string().required("form.error.required").label("Address"),
	address_line_2: yup.string().label("Address"),
	postcode: yup.string().required("form.error.required").label("Postcode"),
	province: yup.string().required("form.error.required").label("Province"),
	city: yup.string().required("form.error.required").label("City"),
	country: yup.string().required("form.error.required").label("Country"),
});

// SIZE SELECTOR SCHEMA
export const sizeSelectorSchema = yup
	.object({
		xx_small: yup
			.number()
			.positive()
			.integer()
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		x_small: yup
			.number()
			.positive()
			.integer()
			.required("form.error.required")
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		small: yup
			.number()
			.positive()
			.integer()
			.required("form.error.required")
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		medium: yup
			.number()
			.positive()
			.integer()
			.required("form.error.required")
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		large: yup
			.number()
			.positive()
			.integer()
			.required("form.error.required")
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		x_large: yup
			.number()
			.positive()
			.integer()
			.required("form.error.required")
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
		xx_large: yup
			.number()
			.positive()
			.integer()
			.min(0)
			.transform((value) => (isNaN(value) ? 0 : value)),
	})
	.required("form.error.required");

// PASSWORD SCHEMA

// CONFIRM PWD SCHEMA
export const ConfirmPasswordSchema = yup.object({
	new_password1: yup
		.string()
		.required("form.error.required")
		.min(8, "form.error.passwordShort"),
	new_password2: yup
		.string()
		.oneOf([yup.ref("new_password1")], "form.error.passwordConfirm"),
});

//////////// TYPES ////////////////

export type sizesSelectorForm = {
	x_small: number;
	small: number;
	medium: number;
	large: number;
	x_large: number;
};

export type registrationForm = {
	first_name: string;
	last_name: string;
	email: string;
	password: string;
};

export type loginForm = {
	email: string;
	password: string;
};
