import React from "react";
import { useState } from "react";
import { Wrap } from "./styles";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { ActionCardEnum, Production, SampleProduction } from "../../beans";

import ActionCard from "../ActionCard";
import { setProductionRequest } from "../../store/Production";
import { priceFormat } from "../../utils/format";
import Divider from "../Divider";
import { getKeyFromValue } from "../../utils/objects";

type Props = {
  orderId: number;
  production: Production;
  designId: number;
};

const SampleSizeSelector = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { production } = props;
  const productionId = props.production.id;

  const [sampleSize, setSampleSize] = useState<keyof SampleProduction>(
    (getKeyFromValue(production.sizes, 1) as
      | keyof SampleProduction
      | undefined) ?? "small",
  );

  const handleSampleSize = (size: keyof SampleProduction) => {
    const initialSampleProductionPayload: Record<
      keyof SampleProduction,
      number | boolean
    > = {
      sample: true,
      small: 0,
      medium: 0,
      large: 0,
      x_large: 0,
    };
    initialSampleProductionPayload[sampleSize] = 1;
    setSampleSize(size);
    dispatch(
      setProductionRequest({
        productionId: productionId,
        orderId: props.orderId,
        designId: props.designId,
        body: initialSampleProductionPayload,
      }),
    );
  };
  return (
    <Wrap>
      <div className="size-editor">
        <ActionCard
          title="S"
          type={ActionCardEnum.simple}
          selected={sampleSize === "small"}
          onClick={() => handleSampleSize("small")}
        />
        <ActionCard
          title="M"
          type={ActionCardEnum.simple}
          selected={sampleSize === "medium"}
          onClick={() => handleSampleSize("medium")}
        />
        <ActionCard
          title="L"
          type={ActionCardEnum.simple}
          selected={sampleSize === "large"}
          onClick={() => handleSampleSize("large")}
        />
        <ActionCard
          title="XL"
          type={ActionCardEnum.simple}
          selected={sampleSize === "x_large"}
          onClick={() => handleSampleSize("x_large")}
        />
      </div>
      <Divider vertical />

      <div className="total-row" key={`total-size`}>
        <label className="label-sm">Total units</label>
        <div className="total">
          <strong>1</strong>
        </div>
      </div>

      <div className="total-row">
        <div className="label-sm">Price per unit</div>
        {production.highest_cost && (
          <div className="total">
            <strong>
              {priceFormat(production.highest_cost?.toString())} €
            </strong>
          </div>
        )}
      </div>
    </Wrap>
  );
};

export default SampleSizeSelector;
