import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  position: sticky;
  bottom: 0;
  padding: 24px 40px;
  background: var(--white);
  border-top: 1px solid var(--purple30);

  .button-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      width: 100%;
    }
  }
  .range-item {
    display: none;
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .range-item {
      display: block;
      min-width: 33%;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    position: fixed;
    width: 100%;
    padding: 24px 8px;
    .button-container {
      gap: 8px;
    }
  }
`;
