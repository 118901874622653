import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { AppDispatch, RootState } from "../../store";
import PaddingManager from "../../components/PaddingManager";
import FormContainer from "../../components/FormContainer";
import Header from "../../components/Header";
import { Wrap } from "./styles";
import Card from "../../components/Card";
import { useForm } from "react-hook-form";
import { resetPasswordRequest } from "../../store/Password";
import Divider from "../../components/Divider";
import { ButtonEnum } from "../../beans";
import Button from "../../components/Button";
import { passwordSchema } from "../../utils/form";
import { useNavigate } from "react-router";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";

const PasswordReset = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const passwordRequestState = useSelector(
    (state: RootState) => state.password,
  );
  const isLogged = useSelector((state: RootState) => state.login.logged);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(passwordSchema),

    shouldFocusError: false,
    mode: "onChange",
  });

  // HOOKS
  useEffect(() => {
    isLogged && navigate(routes.home);
  }, [isLogged, navigate]);

  const handleResetPasswordForm = () => {
    const email = getValues();
    if (email) {
      dispatch(resetPasswordRequest(email));
    }
  };

  return (
    <>
      <Header />
      <Wrap>
        <PaddingManager>
          {!passwordRequestState.email_sent ? (
            <Card title={t("passwordReset.title")}>
              <div
                className="body-sm"
                dangerouslySetInnerHTML={{
                  __html: t("passwordReset.description"),
                }}
              ></div>
              <Divider />
              <div>
                <FormContainer>
                  <form onSubmit={handleSubmit(handleResetPasswordForm)}>
                    <div className="form-row">
                      <div className="input-row full">
                        <label className="label-sm">
                          {t("passwordReset.email")}
                        </label>
                        <input
                          key="email"
                          className={`size-selector-input`}
                          type="text"
                          {...register("email")}
                        />
                        {touchedFields["email"] && errors["email"] && (
                          <small className="error-field">
                            {t(`${errors["email"]?.message}`)}
                          </small>
                        )}
                      </div>
                    </div>
                    <Button
                      style={ButtonEnum.primary}
                      onClick={handleSubmit(handleResetPasswordForm)}
                      loading={passwordRequestState.loading}
                    >
                      {t("passwordReset.button")}
                    </Button>
                    <Button
                      style={ButtonEnum.tertiary}
                      onClick={() => navigate(routes.home)}
                      loading={passwordRequestState.loading}
                    >
                      {t("button.backLogin")}
                    </Button>
                  </form>
                </FormContainer>
              </div>
            </Card>
          ) : (
            <Card title="Email sent">
              <div
                className="body-sm"
                dangerouslySetInnerHTML={{
                  __html: t("passwordReset.emailSent.description", {
                    email: getValues("email"),
                  }),
                }}
              ></div>
            </Card>
          )}
        </PaddingManager>
      </Wrap>
    </>
  );
};

export default PasswordReset;
