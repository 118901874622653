import styled from "styled-components";
export const Wrap = styled.button<{ $selected?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 5.333px;
  border: 1px solid transparent;
  box-sizing: border-box;
  background: var(--gray20);

  border-color: ${(props) =>
    props.$selected ? "var(--purple100)" : "var(--gray30)"};

  &:hover {
    border: 1px solid var(--gray90);
  }

  .thumb {
    max-height: 100%;
    max-width: 100%;
  }
`;
