import React, { useState } from "react";
import { ChangeEvent, DragEvent, useRef } from "react";
import { AlertEnum } from "../../beans";
import Alert from "../Alert";

import { useTranslation } from "react-i18next";
import { trimImage } from "../../utils/image";
import { extractColors } from "extract-colors";
import { FinalColor } from "extract-colors/lib/types/Color";

const FileDrop = () => {
	const { t } = useTranslation();

	const inputFile = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState<
		{ file: HTMLCanvasElement; colors: FinalColor[] }[]
	>([]);

	// HANDLERS
	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();

		// Fetch the files
		const droppedFiles = Array.from(event.dataTransfer.files);
		return fileReader(droppedFiles);
	};

	const onButtonClick = () => {
		if (!inputFile.current) return;
		inputFile.current.click();
	};

	const onfileUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files as ArrayLike<File>);
		const droppedFiles = Array.from(files);
		return fileReader(droppedFiles);
	};

	const fileReader = (filesToRead: File[]) => {
		// Use FileReader to read file content
		filesToRead.forEach((file) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				const image = new Image();
				image.src = reader.result as string;
				const options = {
					pixels: 6400,
					distance: 0.27,
					hueDistance: 0,
					saturationDistance: 0.05,
					lightnessDistance: 1,
					cache: false,
				};

				trimImage(file, reader.result as string).then((context) => {
					extractColors(context.toDataURL(), options).then((colorArray) => {
						setFiles((files) => [
							...files,
							{ file: context, colors: colorArray },
						]);
					});
				});
			};
			reader.onerror = () => {
				<Alert type={AlertEnum.error}>{t("fileDrop.errorFile")}</Alert>;
			};

			reader.readAsDataURL(file);
			return reader;
		});
	};

	return (
		<>
			<div onClick={onButtonClick}>
				<div
					style={{ padding: "20px", background: "#eee", margin: "20px" }}
					className="drop-area"
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<div className="body-sm">{t("fileDrop.description")}</div>
					<input
						id="fileUpload"
						style={{ display: "none" }}
						// TODO : set file extention list in constants
						// accept=".png"
						ref={inputFile}
						multiple
						onChange={(e) => onfileUpload(e)}
						type="file"
					/>
				</div>
			</div>
			{files.map((file, k) => {
				return (
					<div
						key={k}
						style={{ display: "flex", gap: "4px", alignItems: "center" }}
					>
						<img src={file.file.toDataURL()} alt="" width="200px" />
						colors:{" "}
						{file.colors.map((color) => (
							<div
								key={color.hex}
								style={{
									background: color.hex,
									width: "20px",
									height: "20px",
									border: "1px solid black",
								}}
							></div>
						))}
					</div>
				);
			})}
		</>
	);
};

export default FileDrop;
