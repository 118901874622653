import React from "react";
import { Wrap } from "./styles";

type Props = {
  children?: React.ReactNode;
  className: string;
};

const ArtworkArea = (props: Props) => {
  const { className, children } = props;

  return <Wrap className={className}>{children}</Wrap>;
};

export default ArtworkArea;
