import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../api/auth";
import { Billing } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";

export interface BillingState {
  data: Billing | null;
  error: null | string;
  loading: boolean;
  loaded: boolean;
}

const initialState: BillingState = {
  data: null,
  error: null,
  loading: false,
  loaded: false,
};

function* setBillingSaga(
  action: PayloadAction<{
    orderId: string;
    payload: Record<string, unknown>;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const { orderId, payload } = action.payload;

    const headers = getAuthHeader();

    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${orderId}/billing/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      },
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(setBillingSuccess(data));
    } else {
      yield put(setBillingFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (e) {
    // console.error("SHIPPING/SET:", e);
  }
}

function* updateBillingSaga(
  action: PayloadAction<{
    orderId: string;
    payload: Record<string, unknown>;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const { orderId, payload } = action.payload;
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${orderId}/billing/`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      },
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(updateBillingSuccess(data));
    } else {
      yield put(updateBillingFailure(data));

      yield put(showErrorModal(getErrors(data)));
    }
  } catch (e) {
    // console.error("SHIPPING/UPDATE:", e);
  }
}

export const getBillingFromOrderThunk = createAsyncThunk(
  "billing/this_order",
  async (orderId: string) => {
    const headers = getAuthHeader();
    const response = await fetch(
      `${apiUrl}/orders/orders/${orderId}/billing/this_order/`,
      {
        headers: headers,
        credentials: "include",
      },
    );
    return await response.json();
  },
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBilling: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setBillingRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    setBillingSuccess: (state, action) => {
      state.data = action.payload;
      state.error = initialState.error;
      state.loading = false;
      state.loaded = true;
    },
    setBillingFailure: (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
      state.loaded = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateBillingRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    updateBillingSuccess: (state, action) => {
      state.data = action.payload;
      state.error = initialState.error;
      state.loading = false;
      state.loaded = true;
    },
    updateBillingFailure: (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
      state.loaded = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingFromOrderThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(getBillingFromOrderThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getBillingFromOrderThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  resetBilling,
  setBillingRequest,
  setBillingSuccess,
  setBillingFailure,
  updateBillingRequest,
  updateBillingSuccess,
  updateBillingFailure,
} = billingSlice.actions;

export default billingSlice.reducer;
export function* sagas() {
  yield takeEvery(setBillingRequest.type, setBillingSaga);
  yield takeEvery(updateBillingRequest.type, updateBillingSaga);
}
