import styled from "styled-components";

export const Wrap = styled.div<{ $show?: boolean; $full?: boolean }>`
	display: ${(props) => (props.$show ? "inline-flex" : "none")};
	width: ${(props) => (props.$full ? "100%" : "auto")};
	padding: 8px 16px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	&.error {
		background: var(--red20);
		color: var(--red110);
		svg > path {
			fill: var(--red110);
		}
	}
	&.warning {
		background: var(--orange20);
		color: var(--orange110);
		svg > path {
			fill: var(--orange110);
		}
	}
	&.info {
		background: var(--blue20);
		color: var(--blue110);
		svg > path {
			fill: var(--blue110);
		}
	}
	&.success {
		background: var(--green20);
		color: var(--green110);
		svg > path {
			fill: var(--green110);
		}
	}

	.alert-message {
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 8px;
		svg {
			min-width: 24px;
			align-self: baseline;
		}
	}
	.icon {
		min-width: 24px;
		align-self: baseline;
	}
`;
