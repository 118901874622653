import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { Production } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { getCurrentCartRequest, getOrderSummaryRequest } from "../Order";
import { showErrorModal } from "../UI";
import { getUserDesign } from "../UserDesign/selectors";
import { initialProduction_sample } from "../../constants/productions";

export interface ProductionState {
  data: Production | null;
  productionTime: string;
  error: null | string | object;
  loading: boolean;
  loaded: boolean;
}

const initialState: ProductionState = {
  data: null,
  productionTime: "2 - 6",
  error: null,
  loading: false,
  loaded: false,
};

// DEFINE SAGAS

// CREATE PRODUCTION

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* createProductionSaga(): Generator<StrictEffect, void, any> {
  const userDesign = yield select(getUserDesign());

  if (userDesign) {
    try {
      // TODO remove this mock, find a better solution to get init Production
      const initialProduction = initialProduction_sample;
      const headers = getAuthHeader();
      const response = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/production/`,
        {
          method: "POST",
          body: JSON.stringify(initialProduction),
          headers: headers,
          credentials: "include",
        },
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(createProductionSuccess(data));
      } else {
        yield put(createProductionFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: unknown) {
      yield put(createProductionFailure(String(error)));
      yield put(showErrorModal(String(error)));
    }
  }
}

// GET ALL PRODUCTIONS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getAllProductionSaga(): Generator<StrictEffect, void, any> {
  const userDesign = yield select(getUserDesign());

  if (userDesign) {
    const headers = getAuthHeader();

    try {
      const response = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/production/`,
        {
          headers: headers,
          credentials: "include",
        },
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(getAllProductionSuccess(data));
      } else {
        yield put(getAllProductionFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: unknown) {
      console.error("ERROR", error);
      yield put(showErrorModal(String(error)));
    }
  }
}

// GET PRODUCTION BY ID
function* getProductionByIdSaga(
  action: PayloadAction<{ designId: string; productionId: string }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  const { designId, productionId } = action.payload;

  if (designId && productionId) {
    const headers = getAuthHeader();
    try {
      const response = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${designId}/production/${productionId}`,
        {
          headers: headers,
          credentials: "include",
        },
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(getProductionByIdSuccess(data));
      } else {
        yield put(getProductionByIdFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: unknown) {
      console.error("ERROR", error);
      yield put(getProductionByIdFailure(String(error)));
      yield put(showErrorModal(String(error)));
    }
  }
}

function* setProductionSaga(
  action: PayloadAction<{
    productionId: number;
    orderId: number;
    designId: number;
    body: object;
  }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  const { productionId, orderId, designId, body } = action.payload;
  if (designId && productionId) {
    try {
      const headers = getAuthHeader();
      const response = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${designId}/production/${productionId}/`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          headers: headers,
          credentials: "include",
        },
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(setProductionSuccess(data));
        yield put(getOrderSummaryRequest(orderId));
        yield put(getCurrentCartRequest());
      } else {
        yield put(setProductionFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: unknown) {
      console.error("ERROR PRODUCTION", error);
      const errorMessage =
        "The server encountered an unexpected problem. Try again";
      yield put(setProductionFailure(errorMessage));
      yield put(showErrorModal(String(errorMessage)));
    }
  }
}

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    resetProduction: () => initialState,

    createProductionRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    createProductionSuccess: (state, action: PayloadAction<Production>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    createProductionFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getAllProductionRequest: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.loading = true;
    },
    getAllProductionSuccess: (state, action: PayloadAction<Production>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getAllProductionFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProductionByIdRequest: (state, _action) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.loading = true;
    },
    getProductionByIdSuccess: (state, action: PayloadAction<Production>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getProductionByIdFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    setProductionRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        productionId: number;
        orderId: number;
        designId: number;
        body: object;
      }>,
    ) => {
      state.error = initialState.error;
      state.loading = true;
    },
    setProductionSuccess: (state, action: PayloadAction<Production>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    setProductionFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  resetProduction,
  createProductionRequest,
  createProductionSuccess,
  createProductionFailure,
  getAllProductionRequest,
  getAllProductionSuccess,
  getAllProductionFailure,
  getProductionByIdRequest,
  getProductionByIdSuccess,
  getProductionByIdFailure,
  setProductionRequest,
  setProductionSuccess,
  setProductionFailure,
} = productionSlice.actions;

export default productionSlice.reducer;

export function* sagas() {
  yield takeEvery(createProductionRequest.type, createProductionSaga);
  yield takeEvery(getAllProductionRequest.type, getAllProductionSaga);
  yield takeEvery(getProductionByIdRequest.type, getProductionByIdSaga);
  yield takeLatest(setProductionRequest.type, setProductionSaga);
}
