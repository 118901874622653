import React from "react";
import { Route, Routes } from "react-router";
import { routes } from "./constants/routes";
import Design from "./views/Design";
import DesignNew from "./views/DesignNew";
import OrderShipping from "./views/OrderShipping";
import Checkout from "./views/Checkout/indes";
import OrderCompleted from "./views/OrderCompleted";
import NotFound from "./views/NotFound";
import "./App.css";
import Production from "./views/Production";
import PasswordReset from "./views/PasswordReset";
import PasswordConfirm from "./views/PasswordConfirm";

import * as FullStory from "@fullstory/browser";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import Access from "./views/Access";
import ProtectedRoute from "./utils/ProtectedRoute";
import OrderCart from "./views/OrderCart";
import Dashboard from "./views/Dashboard";
import Catalog from "./views/Catalog";
import TestColor from "./components/TestColor";

const App = () => {
	const TRACKING_ID = "G-6DVM5DRTT5"; // your Measurement ID
	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		// Send pageview with a custom path
		ReactGA.send({
			hitType: "pageview",
			page: document.location.pathname,
			title: document.title,
		});
	}, []);
	if (!window._fs_initialized) FullStory.init({ orgId: "o-20T5GR-na1" });

	return (
		<Routes>
			<Route element={<Access />} path={routes.access} />
			<Route
				element={
					<ProtectedRoute>
						<TestColor />
					</ProtectedRoute>
				}
				path="/test"
			/>
			<Route
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
				path={routes.home}
			/>
			<Route
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
				path={routes.newUser}
			/>
			<Route
				element={
					<ProtectedRoute>
						<Design />
					</ProtectedRoute>
				}
				path={routes.design}
			/>
			<Route
				element={
					<ProtectedRoute>
						<Production />
					</ProtectedRoute>
				}
				path={routes.production}
			/>
			<Route
				element={
					<ProtectedRoute>
						<Catalog />
					</ProtectedRoute>
				}
				path={routes.catalog}
			/>

			<Route
				element={
					<ProtectedRoute>
						<DesignNew />
					</ProtectedRoute>
				}
				path="/design/edit/:designId/"
			/>
			<Route
				element={
					<ProtectedRoute>
						<OrderCart />
					</ProtectedRoute>
				}
				path="/order/:orderId/"
			/>
			<Route
				element={
					<ProtectedRoute>
						<OrderShipping />
					</ProtectedRoute>
				}
				path="/order/:orderId/shipping/"
			/>
			<Route
				element={
					<ProtectedRoute>
						<Checkout />
					</ProtectedRoute>
				}
				path="/order/:orderId/checkout/"
			/>
			<Route
				element={
					<ProtectedRoute>
						<OrderCompleted />
					</ProtectedRoute>
				}
				path="/order/:orderId/completed/"
			/>

			<Route element={<PasswordReset />} path={routes.passwordReset} />
			<Route
				element={<PasswordConfirm />}
				path="/auth/password/reset/confirm/:uid/:token/"
			/>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default App;
