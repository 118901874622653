export const apiUrl = process.env.REACT_APP_API_URL;
export const authUrl = process.env.REACT_APP_AUTH_URL;
export const countryApi = process.env.REACT_APP_COUTRY_API;
export const siteUrl = process.env.REACT_APP_SITE_URL;
export const stripePublishableKey =
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const cdn_url = process.env.REACT_APP_CDN_URL;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const googleSecretKey = process.env.REACT_APP_GOOGLE_SECRET_KEY;
