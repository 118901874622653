import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  &.help-container {
    display: flex;
    gap: 16px;

    .card {
      border: 1px solid var(--gray30);
    }
    .card-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: var(--gray130);
      .title-sm,
      .label-lg {
        color: var(--gray130);
      }
      svg {
        path {
          fill: var(--gray130);
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    &.help-container {
      flex-direction: column;
    }
  }
`;
