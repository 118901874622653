import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{ $open?: boolean }>`
  position: absolute;
  z-index: 999999999;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.$open ? "visible" : "hidden")};
  opacity: ${(props) => (props.$open ? "1" : "0")};
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  max-height: 100vh;
  overflow: hidden;

  .infobox-content {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;

    transform: ${(props) =>
      props.$open ? "translateX(0)" : "translateX(100vw)"};
    width: var(--sidebarWidth);
    height: 100vh;
    background-color: var(--white);
    transition: all 0.3s ease-in-out;

    .title {
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: var(--headerHeight);
      border-bottom: 1px solid var(--purple30);
      .title-sm {
        color: var(--gray130);
      }
    }
    .info-content {
      padding: 24px 40px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      hr {
        border: 0;
        border-bottom: 1px solid var(--purple30);
        margin-bottom: 16px;
      }

      table {
        margin-top: 16px;
        width: 100%;
        border: 1px solid var(--gray30);
        border-radius: 4px;
        text-align: center;
        border-collapse: collapse;
        thead {
          background: var(--gray20);
          th {
            padding: 8px;
            border: 1px solid var(--gray30);
          }
        }
        tbody {
          width: 100%;
          td {
            border: 1px solid var(--gray30);
            padding: 8px;
          }
        }
      }

      .infobox-image {
        img {
          height: 240px;
          height: 240px;
          object-fit: cover;
          width: 100%;
          margin: auto;
          display: block;
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    .infobox-content {
      width: var(--sidebarTabletWidth);

      .title,
      .info-content {
        padding: 16px;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .infobox-content {
      width: 100%;
      max-width: 100%;
    }
  }
`;
