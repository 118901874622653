import React from "react";
import { Wrap } from "./styles";
import { Icon as IconType } from "../../beans";

type Props = {
	id?: string;
	icon: IconType;
	info?: boolean;
	class?: string;
};
const Icon = (props: Props) => {
	const { id, icon, info } = props;

	return (
		<Wrap
			id={id}
			viewBox={icon?.viewBox}
			height={icon?.height}
			width={icon?.width}
			role={icon?.role || "img"}
			dangerouslySetInnerHTML={{ __html: icon?.path }}
			$info={info}
			className={props.class}
		></Wrap>
	);
};

export default Icon;
