import React, { ReactElement } from "react";
import { ButtonEnum } from "../../beans";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { close } from "../../constants/icons";
import { MouseEventHandler } from "react";
import { hideInfoBox } from "../../store/UI";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

type Props = {
	title?: string;
	content?: string | ReactElement;
	image: string;
	open?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
};
const InfoBox = (props: Props) => {
	const { open, title, content, image } = props;
	const dispatch = useDispatch<AppDispatch>();

	return (
		<Wrap
			$open={open}
			onClick={() => {
				dispatch(hideInfoBox());
			}}
		>
			<div
				className="infobox-content"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div className="title">
					<div className="title-sm">{title}</div>
					<div>
						<Button
							style={ButtonEnum.icon}
							onClick={() => {
								dispatch(hideInfoBox());
							}}
						>
							<Icon icon={close} />
						</Button>
					</div>
				</div>
				<div className="info-content">
					{image && (
						<div className="infobox-image">
							<img src={image} alt="info" />
						</div>
					)}
					{content && typeof content !== "string" && <>{content}</>}
					{content && typeof content === "string" && (
						<div dangerouslySetInnerHTML={{ __html: content }} />
					)}
				</div>
			</div>
		</Wrap>
	);
};

export default InfoBox;
