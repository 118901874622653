import React from "react";
import { Wrap } from "./styles";

import Icon from "../Icon";
import { ButtonEnum } from "../../beans";
import { stars } from "../../constants/icons";
import {
	showActionModal,
	resetActionModal,
	hideActionModal,
} from "../../store/UI";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useTranslation } from "react-i18next";

type Props = {
	id?: string;
	label?: string;
	url?: string;
	style?: ButtonEnum;
};
const AirTableFormButton = ({ id, label, url, style }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const user = useSelector((state: RootState) => state.user.data);
	const phone = user?.phone?.replace("+", "00") || "";
	return (
		<Wrap id={id}>
			<Button
				style={style || ButtonEnum.icon}
				onClick={() =>
					dispatch(
						showActionModal({
							title: t("airtable.modalTitle"),
							error: `<iframe class="airtable-embed" border="0" src="${url}?&prefill_Email=${user?.email}&prefill_Phone=${encodeURI(phone)}" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>`,
							actions: [],
							onClose: () => {
								dispatch(resetActionModal());
								dispatch(hideActionModal());
							},
						})
					)
				}
			>
				{!style && <Icon icon={stars} />}
				{label}
			</Button>
		</Wrap>
	);
};

export default AirTableFormButton;
