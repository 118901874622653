import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  .design-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }

  .empty-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 40px;

    svg {
      path {
        /* fill: var(--purple100); */
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    .design-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .design-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .design-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .design-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
