import styled from "styled-components";
import { rotate, loading } from "../../design-system/animations";

export const Wrap = styled.div<{ $text?: boolean }>`
  /* position: absolute; */

  animation: ${loading} 1.8s ease-in-out infinite;

  svg {
    animation: ${rotate} 2s linear infinite;
    path {
      fill: #7745ff;
    }
    path:nth-child(2) {
      fill: white;
    }
  }
`;
