import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrderStatusEnum, OrderList as OrderListType } from "../../beans";
import { AppDispatch, RootState } from "../../store";
import { listOrderRequest } from "../../store/Order";
import OrderDetailsCard from "../OrderDetailsCard";
import Icon from "../Icon";
import { emptyOrderPage } from "../../constants/icons";
import LoadingItem from "../LoadingItem";

type Props = {
	minimal?: boolean;
};

const OrderList = (props: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	// LOCAL STATE
	const orderList = useSelector((state: RootState) => state.order.orderList);
	const orderState = useSelector((state: RootState) => state.order);
	// HOOKS
	useEffect(() => {
		dispatch(listOrderRequest());
	}, [dispatch]);

	const hasOrdersToShow = () => {
		if (orderList) {
			return orderList.find(
				(order: OrderListType) => order.status === OrderStatusEnum.payment_made
			);
		}
	};
	const renderOrderList = () => {
		return (
			orderList &&
			orderList.map((order: OrderListType) => {
				return (
					order.status === OrderStatusEnum.payment_made && (
						<OrderDetailsCard
							order={order}
							key={`order_detail_${order.id}`}
							minimal={props.minimal}
						/>
					)
				);
			})
		);
	};
	return (
		<div className="orders-list">
			{orderState.loading && <LoadingItem />}
			{!orderState.loading && renderOrderList()}
			{!orderState.loading && !hasOrdersToShow() && (
				<div className="empty-page">
					<Icon icon={emptyOrderPage} />
					<div className="heading-md">{t("production.noDesign")}</div>
					<div className="title-lg">{t("production.orders")}</div>
				</div>
			)}
		</div>
	);
};

export default OrderList;
