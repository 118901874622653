import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArtworkDetail as ArtworkDetailEnum, ButtonEnum } from "../../beans";
import { AppDispatch, RootState } from "../../store";
import ArtworkDetail from "../ArtworkDetail";
import Button from "../Button";
import Divider from "../Divider";
import SubsectionTitle from "../SubsectionTitle";
import { Wrap } from "./styles";
import { translatePrintLocationById } from "../../utils/translate";
import { selectTab } from "../../store/UI";
import LoadingOptions from "../LoadingOptions";
import { useTranslation } from "react-i18next";
import EditDesignName from "../EditDesignName";
import SummaryCostBreakdown from "../SummaryCostBreakdown";

const Summary = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const userDesignState = useSelector((state: RootState) => state.userDesign);
	const userDesign = userDesignState.data;
	const printLocations = useSelector(
		(state: RootState) => state.printLocations.data
	);
	const selectedFabric = useSelector(
		(state: RootState) => state.fabrics.selectedFabric
	);
	const selectedColorFabric = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);
	const artworks = useSelector(
		(state: RootState) => state.userDesign.data?.print_set
	);

	if (!userDesign || !selectedFabric || !selectedColorFabric)
		return <LoadingOptions />;
	return (
		<Wrap>
			<SubsectionTitle title={t("summary.productName")}></SubsectionTitle>
			<EditDesignName design={userDesign} />

			<Divider />
			<SubsectionTitle title={t("summary.manufactured")}></SubsectionTitle>
			<div
				className="body-sm manufactured-description"
				dangerouslySetInnerHTML={{
					__html: t("summary.manufacturedDescription"),
				}}
			/>

			{/* COST BRAKDONW TABLE */}
			<SummaryCostBreakdown />
			<Divider />
			<SubsectionTitle title={t("summary.fabric")}>
				<Button
					style={ButtonEnum.link}
					id="fabric"
					onClick={() => dispatch(selectTab("fabric"))}
				>
					{t("button.edit")}
				</Button>
			</SubsectionTitle>
			<div className="body-sm">
				{userDesign.item_fabric.col_fabric.fabric}
				{", "}
				<strong>{userDesign.item_fabric.col_fabric.color.name}</strong>
			</div>
			{artworks ? (
				<>
					{artworks.map((artwork: ArtworkDetailEnum, k: number) =>
						artwork.location ? (
							<div className="artwork-details-summary" key={k}>
								<Divider />
								<SubsectionTitle
									title={t(
										`printLocation.${translatePrintLocationById(
											artwork.location,
											printLocations
										)}`
									)}
								/>
								<ArtworkDetail artwork={artwork} />
							</div>
						) : null
					)}
				</>
			) : null}
		</Wrap>
	);
};

export default Summary;
